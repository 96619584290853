import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

import { Document, PDFDownloadLink, pdf, PDFViewer  } from '@react-pdf/renderer';
import domtoimage from 'dom-to-image';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Modal from 'react-modal';

import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';

import QRCode from 'qrcode';

import NewCertGenerator from "tools/NewCertGenerator";
import CertGeneratorPDF from "tools/CertGeneratorPDF"

import Message from "../../tools/Message";

import backImg from "../../tools/credencial_fondo.jpg";
import NoImg from "../../tools/no-img.png"; 

import {Get, Del, GetGuest, Post} from "../../tools/Helper";

function Participantes({ dataCursoProgramado, setDataCursoProgramado }) {
  const [status, setStatus] = useState({status: null, message: ""});
  const [domain, setDomain] = useState('');
  const [qr, setQr] = useState(null);
  const [urlCurso, setUrlCurso] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataCertificado, setDataCertificado] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [credModal, setCredModal] = useState(false);

  const [blob, setBlob] = useState(null);

  const downloadFCertificadoDC3 = async() =>{

    const tmp = await pdf(
      <Document>
        <CertGeneratorPDF data={dataCertificado} qr={qr} />
        <NewCertGenerator data={dataCertificado} qr={qr} />
      </Document>
      ).toBlob();
      setBlob(tmp);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `certificado_${dataCertificado.nombre_cursante}_${dataCertificado.folio_unico_registro}.pdf`;
    link.click();
  }

  const openModalCred = () => {
    setCredModal(true);
  }
  
  const openModal = () => {
    setModalIsOpen(true);
  }
  const closeModal = () => {
    setModalIsOpen(false);
    setCredModal(false);
  }

  const downloadPDF = async(idCursanteProgramado, type) => { // prevent page reload
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Get(`certificado-cursante-programado/${idCursanteProgramado}`);
    if(request.status === "success"){
      const row = request.data;
      const qrData = await QRCode.toDataURL(`https://certificados.paymun.adaptingweb.com/${row.folio_unico_registro}/${row.token_certificado}-${row.cursante.id_cursante}`);
      setDataCertificado(row);
      setQr(qrData);
      if(type === 'certificado'){
        openModal();
      }else if(type === 'credencial'){
        openModalCred();
      }
      setLoading(false);
    }else{
      setStatus({status:request.status, message:"Error."});
      setLoading(false);
    }
  }

  const downloadCred = () => {
    domtoimage
      .toJpeg(document.getElementById("canv"), { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `credencial_${dataCertificado.folio_unico_registro}_${dataCertificado.nombre_cursante}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  }

  const navigate = useNavigate();
  const navigateResult = (row) =>{
    window.open(`/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=final&email=${row.correo}&attempt=${row.num_oportunidades > 1 ? row.num_oportunidades - 1 : row.num_oportunidades}`, '_blank', 'noreferrer');
    //  navigate(`/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=final&email=${row.correo}&attempt=${row.num_oportunidades - 1}`);        

  }


  const buttonPdf = (row) => <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="info" iconOnly onClick={()=>downloadPDF(row.id_cursante_programado, 'certificado')}><Icon>picture_as_pdf</Icon></MDButton>;
  const buttonPdfCredencial = (row) => <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="info" iconOnly onClick={()=>downloadPDF(row.id_cursante_programado, 'credencial')}><Icon>picture_as_pdf</Icon></MDButton>;
  const buttonReprobado = (row) => <MDButton onClick={()=>navigateResult(row)} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="error" iconOnly >{row.calificacion_final}</MDButton>;
  const buttonAprobado = (row) => <MDButton onClick={()=>navigateResult(row)} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="success" iconOnly >{row.calificacion_final}</MDButton>;

  const columns = [
    {
        name: 'Nombre Completo',
        selector: row => row.nombre_completo,
    },
    {
      name: 'CURP',
      selector: row => row.curp,
    },
    {
      name: 'Correo',
      selector: row => row.correo,
    },
    {
      name: 'Diagnóstica',
      selector: row => row.calificacion_diagnostica,
    },
    {
      name: 'Final',
      selector: row => parseInt(row.calificacion_final, 10) < 7 ? buttonReprobado(row) : buttonAprobado(row),
    },
    {
      name: 'Servicio',
      selector: row => row.resultado_encuesta ? 'Contestado' : 'No contestado',
    },
    {
      name: 'PDF.',
      selector: row => parseInt(row.calificacion_final, 10) > 7 ? buttonPdf(row) : '',
      compact: true,
    },
    {
      name: 'Cred.',
      selector: row => row.cred === "1" &&  parseInt(row.calificacion_final, 10) > 7 ? buttonPdfCredencial(row) : "",
      compact: true,
    },
  ];

  const tableData = {
    columns,
    data
  };

  const generateQRCurso = async () => {
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName= fullpath.replace(regex, '');
    setDomain(domainName);
    const url = `${domainName}/alta-cursantes-curso?tk=${dataCursoProgramado.token_qr}`;
    const qrData = await QRCode.toDataURL(url);
    setUrlCurso(url);
    await setQr(qrData);
  }

  const copyLink = () =>{
    navigator.clipboard.writeText(urlCurso);
    setStatus({status: 'success', message: 'Enlace copiado en portapapeles...'});
  }

  const navigateAdminParticipantes = () =>{
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName= fullpath.replace(regex, '');
    const url = `${domainName}/admin-cursantes-curso?tk=${dataCursoProgramado.token_qr}`;
    window.open(url, '_blank');
  }

  const getCursantesCursos = async ()=>{
    setStatus({status: null, message: ""});

    const request = await Get(`cursante-programado/${dataCursoProgramado.id_curso_programado}`);
    
    if(request.status === "success"){
        setData(request.data);
        return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  useEffect(()=>{
    generateQRCurso();
    getCursantesCursos();
  },[]);

  return (
  <MDBox>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <Grid item xs={6}>
            -
          </Grid>
          <MDBox display="flex" justifyContent="space-between" tem xs={6}>
            <p style={{ lineHeight: '1.4rem', fontSize: '1rem' }}>Escaneé el código QR o comparta este link para agregar participantes:</p>
            <div>
                <img src={qr} alt="qr" width={100}  />
            </div>
            <div>
              <p style={{ lineHeight:'1.4rem', fontSize: '1rem' }}>{urlCurso}</p>
              <MDButton variant="gradient" color="success" fullWidth style={{ color: 'white' }} onClick={()=>copyLink()} >
                Copiar enlace
              </MDButton>
              <MDButton variant="gradient" mt={3} color="dark" fullWidth style={{ color: 'white', marginTop: 5 }} onClick={()=>navigateAdminParticipantes()} >
                Administrar participantes
              </MDButton>
            </div>
            
          </MDBox>
        </MDBox>
        <Message status={status} />
        <MDBox>
          <DataTableExtensions
            {...tableData}
            export={false}
            print={false}
            filterPlaceholder="Buscar..."
          >
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Participantes por página:', 
                rangeSeparatorText: 'de', 
                noRowsPerPage: false, 
                selectAllRowsItem: true, 
                selectAllRowsItemText: 'Todos'
              }}
              noDataComponent={<MDBox py={5}>No se encontraron participantes.</MDBox>}
            />
          </DataTableExtensions>
        </MDBox>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Modal Certificado"
        >
        <Grid container spacing={6} pb={2}>
          <Grid item xs={6}>
            <MDButton variant="gradient" color="success" fullWidth onClick={()=>downloadFCertificadoDC3()}>
              Descargar
            </MDButton>
          </Grid>
          <Grid item xs={6} textAlign="right" >
              <MDButton variant="gradient" color="error" fullWidth onClick={()=>closeModal()}>
                Cerrar
              </MDButton>
          </Grid>
        </Grid>
          { dataCertificado && qr ? 
            <PDFViewer width="100%" height="96%" scale="3.0">
              <Document>
                <CertGeneratorPDF data={dataCertificado} qr={qr} />
                <NewCertGenerator data={dataCertificado} qr={qr} />
              </Document>
            </PDFViewer> : "Cargando..."
          }
        </Modal>
        <Modal
          isOpen={credModal}
          onRequestClose={closeModal}
        >
         <Grid container spacing={6} mb={2}>
          <Grid item xs={6}>
            <MDButton variant="gradient" color="success" fullWidth onClick={()=>downloadCred()}>
              Descargar
            </MDButton>
          </Grid>
          <Grid item xs={6} textAlign="right" >
              <MDButton variant="gradient" color="error" fullWidth onClick={()=>closeModal()}>
                Cerrar
              </MDButton>
          </Grid>
        </Grid>
        <div className="cont">
          { dataCertificado && qr ? <div className="pos-rel border1" id="canv">
              <img
                alt="bg"
                src={backImg}
                className="pos-rel"
              />
              <img
                alt="foto"
                src={dataCertificado.cursante.evidencias && dataCertificado.cursante.evidencias.foto ? dataCertificado.cursante.evidencias.foto : NoImg}
                className="avatar"
              />
              <img src={qr} alt="qr" className="qr" />
              <div className="nombre-acreditado">
                Nombre del acreditado
                <br />
                <span className="bold">{ dataCertificado.nombre_cursante }</span>
              </div>
              <div className="curso">
                Curso:
                <br />
                <span className="bold">{ dataCertificado.curso.nombre }</span>
              </div>
              <div className="fecha-finalizacion">
                Fecha de finalización:
                <br />
                <span className="bold">{ dataCertificado.fecha_termino }</span>
              </div>
              <div className="no-certificacion">
                No. de certificación:
                <br />
                <span className="bold">{ dataCertificado.folio_unico_registro }</span>
              </div>
              <div className="desarrollo">Desarrollo de Talento</div>
              <div className="humano">Humano</div>
            </div> : "Cargando..."}
        </div>
      </Modal>
      </Grid>
    </Grid>
  </MDBox>
  );
}

Participantes.propTypes = {
  dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
};

export default Participantes;
