/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";
import { Input, Modal } from "@mui/material";

import { Link } from "react-router-dom";
import Message from "tools/Message";

import {Get, Post, Put} from "../../tools/Helper";


function ListaFact() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const [errorFields, setErrorsFields] = useState("");
  const [openPDF, setOpenPDF] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [formData, setFormData] = useState({
    motivo: '',
    folioSustituto: '',
  });

  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async e =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Get('facturas');
    if(request.status === "success"){
      setData(request.data);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);

  const downloadPDF = async(row) => {
    if(!row.cfdi_uid){
      return
    }
    const route = `DownloadCFDIpdf/${row.cfdi_uid}`;
    setOpenPDF(true);
    setSelectedData(row);
    const send = await Get(route);
    if (send.code === 200) {
        const base64String = send.base64_pdf;
        const raw = window.atob(base64String);
        const rawLength = raw.length;

        const uint8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i+= 1) {
          uint8Array[i] = raw.charCodeAt(i);
        }
        
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
    
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Factura_${row.cfdi_uid}.pdf`;
        link.click();
        setOpenPDF(false);
    }
  }

  const downloadXML = async(row) => {
    if(!row.cfdi_uid){
      return
    }
    const route = `DownloadCFDIxml/${row.cfdi_uid}`;
    setOpenPDF(true);
    setSelectedData(row);
    const send = await Get(route);
    if (send.code === 200) {
        const base64String = send.base64_pdf;
        const raw = window.atob(base64String);
        const rawLength = raw.length;

        const uint8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i+= 1) {
          uint8Array[i] = raw.charCodeAt(i);
        }
        
        const blob = new Blob([uint8Array], { type: 'application/xml' });
    
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Factura_${row.cfdi_uid}.xml`;
        link.click();
        setOpenPDF(false);
    }
  }

  const cancelBill=(row)=>{
    setSelectedData(row);
    setOpenCancel(true);
  }

  const paidBill=(row)=>{
    setSelectedData(row);
    setOpenApprove(true);
  }

  const sendcCancelCFDI = async() =>{
    setStatus({status: null, message: ""});

    if(formData.motivo === ''){
      setErrorsFields([
        ...errorFields, 'motivo'
      ]);
      return;
    }

    if(formData.motivo === '01' && formData.folioSustituto === ''){
      setErrorsFields([
        ...errorFields, 'folioSustituto'
      ]);
      return;
    }
    setStatus({status: "warning", message: "Cancelando Factura..."});
    setOpenCancel(false);
    const newData = {
      ...formData,
      cfdi_uid: selectedData.cfdi_uid
    }
    const route = `CancelCFDI`;
    const send = await Post(route, newData);
    if (send.code === 200) {
      setStatus({
        status: "success",
        message: "Su factura ha cancelado satisfactoriamente",
        label: "success",
      });
      setTimeout(()=>{
        setStatus({status: null, message: ""});
      },4000)
      getData();
      return;
    }

    if (send.code === 401) {
      setStatus({
        status: "error",
        message: send.message,
        label: "error",
      });
      setOpenCancel(false);
      setTimeout(()=>{
        setStatus({status: null, message: ""});
      },4000)
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const sendApproveBill = async() =>{
    setOpenApprove(false);
    setStatus({status: null, message: ""});

    const statusBill = {
      id_oss: selectedData.id_oss,
      estatus_cfdi: 'Pagado'
    }
    const request = await Put(`pagar-factura/${selectedData.id_oss}`,statusBill) ;

    if(request.status === "success"){
      setLoading(false);
      setStatus({status: 'success', message: "Se ha generado la OSS"});
      getData();
      return;
    }

    setStatus({status: 'error', message: "No se pudo crear la OSS"});
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const buttonDownloadPDF = (row)=> <Icon onClick={()=>downloadPDF(row)} style={{ cursor: "pointer"}} fontSize="medium" color="primary">picture_as_pdf</Icon>;
  const buttonDownloadXML = (row)=> <Icon onClick={()=>downloadXML(row)} style={{ cursor: "pointer"}} fontSize="medium" color="primary">code</Icon>;
  const buttonStatus = (row)=> <MDTypography color={row.estatus_cfdi === 'Facturado' || row.estatus_cfdi === 'Pagado' ? 'success' : 'error'} fontSize="medium" fontWeight="bold">{row.estatus_cfdi}</MDTypography>;
  const buttonCancelBill = (row) => <MDButton size="small" style={{ width: '100%', padding: 10 }} variant="gradient" color={parseInt(row.cancelado_cfdi,10) === 1 ? 'primary' : 'dark'} iconOnly onClick={()=>cancelBill(row)}><Icon>cancel</Icon>{parseInt(row.cancelado_cfdi,10) === 1 ? 'Cancelado' : 'Cancelar'}</MDButton>;
  const buttonPaid = (row) => <MDButton size="small" style={{ width: '100%', padding: 10 }} variant="gradient" color={row.estatus_cfdi === 'Pagado' ? 'info' : 'dark'} iconOnly onClick={()=>paidBill(row)}><Icon>check_circle</Icon>{row.estatus_cfdi === 'Pagado' ? 'Pagado' : 'Pagar'}</MDButton>;

  const columns = [
      {
        name: 'N° Cotización',
        selector: row => row.folio_cfdi,
        sortable: true,
      },
      {
          name: 'N° Cotización',
          selector: row => row.num_cotizacion,
          sortable: true,
      },
      {
          name: 'Cliente',
          selector: row => row.cliente_nombre,
          wrap: true
      },
      {
          name: 'Total',
          selector: row => row.total,
      },
      {
        name: 'Fecha',
        selector: row => row.fecha_cotizacion,
        sortable: true,
      },
      {
        name: 'PDF',
        selector: row => buttonDownloadPDF(row),
        compact: true
       },
       {
        name: 'XML',
        selector: row => buttonDownloadXML(row),
        compact: true
       },
       {
        name: 'Estatus',
        selector: row => row.estatus_cfdi ,
        compact: true,
        sortable: true,
       },
       {
        name: '',
        selector: row =>  buttonPaid(row),
        compact: true
      },
       {
        name: '',
        selector: row => buttonCancelBill(row),
        compact: true
      },
  ];

  const tableData = {
    columns,
    data,
  };

  const styleCancel = {
    position: 'absolute',
    background: '#ffffff00',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
        
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Lista de Facturas
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" />
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  <Message status={status} />
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox> :
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'OSS por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    </DataTableExtensions>
                  }
                </MDBox>
              </MDBox>
            </Card>
            </Collapse>
            </Fade>
          </Grid>
        <Modal
            open={openPDF}
            onClose={()=>setOpenPDF(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
          <MDBox sx={styleCancel}>
            <Grid container xs={12} mt={1} spacing={2} justifyContent='center'>
              <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  Descargando Factura No: <b>{selectedData.folio_cfdi}</b>, espere...
              </MDTypography>   
            </Grid>
          </MDBox>
        </Modal>
        <Modal
          open={openCancel}
          onClose={()=>setOpenCancel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <MDBox sx={styleCancel}>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
                ¿Está seguro que desea cancelar la factura:  <b>{selectedData.folio_cfdi}</b>?
            </MDTypography>
          <Grid container xs={12} mt={1} spacing={2} justifyContent='center'>
            <Grid xs={12} md={12} px={1}>
              <MDTypography variant="caption">Motivo*: </MDTypography>
              <select
                name = "motivo"
                value = {formData.motivo}
                onChange={setState}
                className= {errorFields.includes("motivo") ? 'error-field' : null}
                style={{ borderColor: 'gray', padding: 5, width: '100%' }}
              >
                <option value="">Seleccione una opción</option>
                <option value="01">Comprobante emitido con errores con relación</option>
                <option value="02">Comprobante emitido con errores sin relación</option>
                <option value="03">No se llevó a cabo la operación</option>
                <option value="04">Operación nominativa relacionada en la factura global</option>
              </select>
              <MDTypography variant="caption">*Indica motivo por el cual es solicitada la cancelación.</MDTypography>
            </Grid>
            <Grid xs={12} md={12} px={1}>
              <MDTypography variant="caption">UID CFDI*: </MDTypography>
              <Input
                name = "folioSustituto"
                style={{width: '100%'}}
                value = {formData.folioSustituto}
                onChange={setState}
                className= {errorFields.includes("folioSustituto") ? 'error-field' : null}
              />
              <MDTypography variant="caption">*Indica el UID CFDI que reemplazara el CFDI cancelado.</MDTypography>
            </Grid>
            <Grid item >
                <MDButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick = {()=>sendcCancelCFDI(false)}
                >
                    Sí, Cancelar factura
              </MDButton>
            </Grid>
            <Grid item >
              <MDButton
                md={6}
                variant="gradient"
                color= "dark"
                size="medium"
                onClick = {()=>setOpenCancel(false)}
              >
                Cerrar
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
      <Modal
          open={openApprove}
          onClose={()=>setOpenApprove(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <MDBox sx={styleCancel}>
            <MDTypography id="modal-modal-title" variant="h6" component="h2">
                ¿Está seguro que desea cambiar el estatus de la factura:  <b>{selectedData.folio_cfdi}</b>?
            </MDTypography>
          <Grid container xs={12} mt={1} spacing={2} justifyContent='center'>
            <Grid item >
                <MDButton
                    md={6}
                    variant="gradient"
                    color= "info"
                    size="medium"
                    onClick = {()=>sendApproveBill(false)}
                >
                    Sí, Marcar pagado
              </MDButton>
            </Grid>
            <Grid item >
              <MDButton
                md={6}
                variant="gradient"
                color= "dark"
                size="medium"
                onClick = {()=>setOpenApprove(false)}
              >
                Cerrar
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
        </Grid>
      </MDBox>
      {// selectedData.length !== 0 ? <PDFDownloadLink document={<PDFGenerator data={selectedData} />} fileName={`oss_${selectedData.id_oss}`} ref={downloadButton}/> : "bbbb"
      }
      <Footer />
    </DashboardLayout>
  );
}

export default ListaFact;
