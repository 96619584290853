/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandTalent from "assets/images/logo_talento.png";
import brandDark from "assets/images/logo-ct-dark.png";

import Pregunta from "layouts/catalogo/Pregunta";

import OssResponsable from "layouts/oss-responsable";
import OssArchivo from "layouts/oss-responsable/archivadas";
import VerDetalles from "layouts/mis-oss/VerDetalles";
import Evidencias from "layouts/mis-oss/Evidencias";
import Dashboard2 from "layouts/dashboard/Dashboard2";
import ResponderPregunta from "layouts/oss-responsable/responderPregunta";

import CursanteExterno from "layouts/cursantes-externo/Index";
import ClienteExterno from "layouts/cliente-externo/Index"
import Certificados from "layouts/certificados";

import EvaluacionCursante from "layouts/programacion/evaluaciones/EvaluacionCursante";

import Cursantes from "layouts/cursantes/Index";

import Logout from "auth/Logout";
import SignIn from "layouts/authentication/sign-in";
import AltaCursantes from "layouts/programacion/AltaCursantes";
import CursanteExternoCursos from "layouts/programacion/CursanteExterno";
import Programacion from "layouts/programacion";

import { USER_INSTRUCTOR, USER_ADMIN } from "constans";

import RouteGuard from './auth/RouteGuard';
import RouteGuard2 from './auth/RouteGuard2';

import CursanteInvitado from './layouts/cursantes-externo/Index';
import ClienteInvitado from './layouts/catalogo/ClienteExterno'

import UserConfig from "./layouts/user-reset"
import RecoverPassword from "./layouts/user-reset/recover-password"
import ResetPassword from "./layouts/user-reset/reset-password"

import DirectDownload from './DirectDownload';

import './style.css';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  const [searchParams, setSearchParams] = useSearchParams();
  const tokenUserExternal = searchParams.get("tk");

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  /*
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  */

  useEffect(() => {
      setUserData(JSON.parse(localStorage.getItem("user")));
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  
  const userCursanteClienteRoutes = [
    {
      type: "collapse",
      name: "Perfil Usuario",
      key: "cursantes-externo",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/cursantes-externo",
      component: <CursanteExterno />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const userCursanteRoutes = [
    {
      type: "collapse",
      name: "Perfil Usuario",
      key: "cursantes-externo",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/cursantes-externo",
      component: <CursanteExterno />,
    },
    {
      type: "collapse",
      name: "Mis Certificados",
      key: "certificados/lista",
      icon: <Icon fontSize="small">list_alt</Icon>,
      route: "/certificados/lista",
      component: <Certificados />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const userClienteRoutes = [
    {
      type: "collapse",
      name: "Mi perfil de empresa",
      key: "clientes-externo",
      icon: <Icon fontSize="small">list_alt</Icon>,
      route: "/clientes-externo",
      component: <ClienteExterno />,
    },
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos-programados",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Participantes",
      key: "catalogos-certificado/cursantes",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/catalogos-certificado/cursantes",
      component: <Cursantes />,
    },
    {
      type: "collapse",
      name: "Mi Usuario",
      key: "user-config",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "user-config",
      component: <UserConfig />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];
    
  const InstructorRoutes = [
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos-programados",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
  ];

  const userRoutes = [
    {
      type: "collapse",
      name: "Mis OSS",
      key: "mis-oss",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/mis-oss",
      component: <OssResponsable />,
    },
    {
      type: "collapse",
      name: "OSS Archivadas",
      key: "oss-archivadas",
      icon: <Icon fontSize="small">post_add</Icon>,
      route: "/oss-archivadas",
      component: <OssArchivo />,
    },
    {
      type: "collapse",
      name: "Cursos",
      key: "cursos-programados",
      icon: <Icon fontSize="small">feed</Icon>,
      route: "/cursos-programados",
      component: <Programacion />,
    },
    {
      type: "collapse",
      name: "Cerrar Sesión",
      key: "logout",
      icon: <Icon fontSize="small">exit_to_app</Icon>,
      route: "/logout",
      component: <Logout />,
    },
    {
      name: "Acciones",
      key: "responder",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/acciones-oss/:idOss/servicio/:idServ",
      component: <ResponderPregunta />,
    },
    {
      name: "Detalles de Oss",
      key: "detalles",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/detalles/:id",
      component: <VerDetalles />,
    },
    {
      name: "Evidencias Subidas",
      key: "detalles",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/evidencias/:id/servicio/:idServ",
      component: <Evidencias />,
    },
  ];

  const getRoutesCustom = () =>{
    let routesCust = userRoutes;
    if(tokenUserExternal){
      routesCust =[];
    }

    if(userData){
      if(userData.user){
        if(userData.user.admin === USER_ADMIN){
          routesCust = routes;
        }else if(userData.user.tipo === 'cursante'){
          routesCust = userCursanteRoutes;
        }else if(userData.user.tipo === 'cliente'){
          routesCust = userClienteRoutes;
        }else if(userData.user.tipo === 'cursante-cliente'){
          routesCust = userCursanteClienteRoutes;
        }else if(userData.user.admin === USER_INSTRUCTOR){
          routesCust = InstructorRoutes;
        }
      }
    }
    return routesCust;
  }


  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  // console.log("aaaa");

  const content = useMemo(() => <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandTalent={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandTalent}
            brandName="Paymun"
            routes={getRoutesCustom()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        <Route exact path='/' element={<RouteGuard/>}>
          <Route exact path='/' element={<RouteGuard2/>} />
          {getRoutes(getRoutesCustom())}
          <Route exact path="descargar/:id" element={<DirectDownload />} />
          <Route path="catalogos/servicios/:id/acciones" element={<Pregunta />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
        { /* <Route exact path='' element={} />
                <Route exact path='/preguntas/:id' element={<ResponderPregunta />} />
                <Route exact path='/preguntas-oss/:idOss/servicio/:idServ' element={<ResponderPregunta />} />
                <Route exact path='/test2' element={<ResponderPregunta />} /> */ }
        <Route exact path='/login' element={<SignIn />} />
        <Route exact path='/login-red' element={<RouteGuard2 />} />
        <Route exact path='/cursante-invitado' element={<CursanteInvitado/>} />
        <Route exact path='/cursante-invitado-empresa' element={<CursanteInvitado/>} />
        <Route exact path='/cliente-invitado' element={<ClienteInvitado/>} />
        <Route exact path='/alta-cursantes-curso' element={<CursanteExternoCursos/>} />
        <Route exact path='/admin-cursantes-curso' element={<AltaCursantes/>} />
        <Route exact path='/evaluacion-curso' element={<EvaluacionCursante/>} />
        <Route exact path='/recover-password' element={<RecoverPassword/>} />
        <Route exact path='/reset-password' element={<ResetPassword/>} />
      </Routes>
    </ThemeProvider>,[layout, pathname]);

  return content;
}
