
import React, { useState, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Select from 'react-select';
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import { useNavigate } from "react-router-dom";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { USER_ADMIN } from "constans";
import Programar from './Programar'

import {Get, Post} from "../../tools/Helper";
import Message from "../../tools/Message";

function CursosProgramados() {
  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestringStart = `${today.getFullYear()-2}-01-01`;
  const datestringEnd = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+2}-${zeroDate}${today.getDate()}`;

  const scrollRef = useRef(React.createRef());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDetCurso, setDataDetCurso] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  
  const [dataSearch, setDataSearch] = useState({
    fecha_inicio: datestringStart,
    fecha_fin: datestringEnd,
  });
  const [dataCatalogs, setDataCatalogs] = useState({
    clientes: [],
    cursos: [],
    instructores: [],
  });
  const [status, setStatus] = useState({status: null, message: ""});
  const [show, setShow] = useState(true);

  const emptySearch = {
    fecha_inicio: datestringStart,
    fecha_fin: datestringEnd,
    id_cliente: null,
    id_curso: null,
    id_firmante: null,
    municipio_alcaldia: '',
    estado: '',
    estatus: null
  }

  const handleChange = (e, name = "", value = "") =>{
  
    if(name === ""){
        setDataSearch({...dataSearch, [e.target.name]:e.target.value});
      }else{
        setDataSearch({...dataSearch, [value]:e[value], [name]:e});
      }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const searchCursos = async() => {
    console.log(dataSearch)
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Post(`buscar-cursos`, dataSearch);
    
    if(request.status === "success"){
      setData(request.data);
      setStatus(request);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  const resetSearch = () =>{
    setDataSearch(emptySearch);
  }

  const getCursosProgramados = async ()=>{
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`programar-curso`);
    
    if(request.status === "success"){
      setData(request.data.programados);
      setDataCatalogs(request.data.catalogos);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  const getDetCurso = async (idCursoProgramado)=>{
    setStatus({status: null, message: ""});
    const request = await Get(`programar-curso/${idCursoProgramado}`);
    
    if(request.status === "success"){
      setDataDetCurso(request.data);
      setShow(false);
      setIsEditing(true);
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }
  
  useEffect(()=>{
    getCursosProgramados();
  },[]);

  const statusCurso = (row) =>(
    <div>
      { row.estatus === 'Programado' ? <MDTypography variant="p" color="info" fontWeight="medium" textTransform="capitalize" pt={2}>{row.estatus}</MDTypography> : null }
      { row.estatus === 'En Progreso' ? <MDTypography variant="p" color="warning" fontWeight="medium" textTransform="capitalize" pt={2}>{row.estatus}</MDTypography> : null }
      { row.estatus === 'Completado' ? <MDTypography variant="p" color="success" fontWeight="medium" textTransform="capitalize" pt={2}>{row.estatus}</MDTypography> : null }
    </div>
  )

const showCurso = (item, idCursoProgramado) => (
  <MDButton style={{textAlign: 'left'}} onClick={()=>getDetCurso(idCursoProgramado)} variant="p" color="info" fontWeight="medium" textTransform="capitalize" pt={2}>{item}</MDButton>
)

  const columns = [
    {
        name: 'ID',
        selector: row => showCurso(row.clave_curso_programado, row.id_curso_programado),
    },
    {
      name: 'Cliente',
      selector: row => showCurso(row.nombre_comercial, row.id_curso_programado),
      wrap: true,
      grow:2
    },
    {
      name: 'Curso',
      selector: row => row.nombre_curso,
      wrap: true,
      grow:2
    },
    {
      name: 'Instructor',
      selector: row => row.instructor,
    },
    {
      name: 'Estudiantes',
      selector: row => row.cursante_programado.length,
    },
    {
      name: 'Inicio',
      selector: row => row.fecha_inicio,
    },
    {
      name: 'Fin',
      selector: row => row.fecha_fin,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio_alcaldia,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
    },
    {
      name: 'Estatus',
      selector: row => statusCurso(row),
    },
  ];

  const tableData = {
    columns,
    data
  };

  const estatus = [
    { estatus: 'Programado'},
    { estatus: 'En Proceso'},
    { estatus: 'Completado'},
  ]

  const getCliente = dataCatalogs.clientes.find(option => option.id_cliente === dataSearch.id_cliente);
  const getCursos = dataCatalogs.cursos.find(option => option.id_curso === dataSearch.id_curso);
  const getInstrutores = dataCatalogs.instructores.find(option => option.id_firmante === dataSearch.id_firmante);
  const getEstatus = estatus.find(option => option.estatus === dataSearch.estatus);

   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
      <Fade in={show} timeout={800}>
      <Collapse in={show} timeout={50}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                  variant="gradient" bgColor="info"
                  borderRadius="lg" coloredShadow="info"
                >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Cursos programados
                    </MDTypography>
                  </Grid>
                  {
                    parseInt(userData.user.admin, 10) === USER_ADMIN ?
                      <Grid item xs={6} textAlign="right" >
                        <MDButton variant="gradient" color="light" style={{ borderRadius: 5, paddingLeft: 15, paddingRight: 15 }}
                          onClick={()=>{ 
                              setShow(false);
                              setIsEditing(false);
                            }
                          }>
                          <Icon fontSize="small">add</Icon>
                          &nbsp;Nuevo
                        </MDButton>
                      </Grid>
                    : null
                  }

                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container pb={3} spacing={1}>
                      <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 30 }}>
                        <MDTypography variant="caption" pt={3} pr={3}>Nombre Empresa</MDTypography>
                        <Select className={`z-10 ${verifyError("id_cliente") ? "border-red" : ""}`}
                          options={dataCatalogs.clientes} placeholder="Nombre Empresa"
                          value={getCliente || ''} onChange={(e) => handleChange(e,"cliente","id_cliente")}
                          getOptionValue={(option) => `${option.id_cliente}`}
                          getOptionLabel={(option) => `${option.nombre_comercial}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 20 }}>
                        <MDTypography variant="caption" pt={3} pr={3}>Curso</MDTypography>
                        <Select className={`z-10 ${verifyError("id_cliente") ? "border-red" : ""}`}
                          options={dataCatalogs.cursos} placeholder="Curso"
                          value={getCursos || ''} onChange={(e) => handleChange(e,"curso","id_curso")}
                          getOptionValue={(option) => `${option.id_curso}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 10 }}>
                        <MDTypography variant="caption" pt={3} pr={3}>Instructor(a)</MDTypography>
                        <Select className={`z-10 ${verifyError("id_cliente") ? "border-red" : ""}`}
                          options={dataCatalogs.instructores} placeholder="Instructor(a)"
                          value={getInstrutores || ''} onChange={(e) => handleChange(e,"instructor","id_firmante")}
                          getOptionValue={(option) => `${option.id_firmante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} mt="auto">
                        <MDInput type="text" label="Municipio/Alcaldía" name="municipio_alcaldia"
                          error={verifyError("municipio_alcaldia")} errorMsg={getError("municipio_alcaldia")}
                          value={dataSearch.municipio_alcaldia} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6} mt="auto">
                        <MDInput type="text" label="Estado" name="estado"
                          error={verifyError("estado")} errorMsg={getError("estado")}
                          value={dataSearch.estado} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="date" label="Fecha inicio" name="fecha_inicio"
                          error={verifyError("fecha_inicio")} errorMsg={getError("fecha_inicio")}
                          value={dataSearch.fecha_inicio} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="date" label="Fecha Termino" name="fecha_fin"
                          error={verifyError("fecha_fin")} errorMsg={getError("fecha_fin")}
                          value={dataSearch.fecha_fin} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 9 }}>
                        <MDTypography variant="caption" pt={3} pr={3}>Estatus</MDTypography>
                        <Select className={`z-101 ${verifyError("estatus") ? "border-red" : ""}`}
                          options={estatus} placeholder="Estatus"
                          value={getEstatus || ''} onChange={(e) => handleChange(e,"value","estatus")}
                          getOptionValue={(option) => `${option.estatus}`}
                          getOptionLabel={(option) => `${option.estatus}`}
                        />
                      </Grid>
                    </Grid>
                    <Grid container pb={3} spacing={1}>
                      <Grid item xs={12} md={6} mt="auto">
                        <MDButton md={4} variant="gradient" color="dark" style={{ color: 'white', borderRadius: 5 }}
                          onClick = {()=>resetSearch()}
                          fullWidth >
                          Reiniciar <Icon fontSize="medium">refresh</Icon>
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} md={6} mt="auto">
                        <MDButton md={4} variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                          onClick={()=>searchCursos()}
                          fullWidth >
                          Burcar <Icon fontSize="medium">search</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                    <Grid pb={3}>
                    {loading ? 
                      <MDBox display="block" fullWidth textAlign="center" p={5}>
                        <CircularProgress size="10rem" /> 
                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                      </MDBox>
                      : 
                      <MDBox>
                        <DataTableExtensions
                          {...tableData}
                          export={false}
                          print={false}
                          filterPlaceholder="Buscar..."
                        >
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationComponentOptions={{
                              rowsPerPageText: 'Cursos por página:', 
                              rangeSeparatorText: 'de', 
                              noRowsPerPage: false, 
                              selectAllRowsItem: true, 
                              selectAllRowsItemText: 'Todos'
                            }}
                            noDataComponent={<MDBox py={5}>No se encontraron cursos.</MDBox>}
                          />
                        </DataTableExtensions>
                      </MDBox>
                    }
                    </Grid>
                    <Message status={status} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <Programar getCursosProgramados = {getCursosProgramados} setIsEditing = {setIsEditing} isEditing = {isEditing} dataDetCurso = {dataDetCurso} setShow={setShow}  />
          </Collapse>
        </Fade>
      </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default CursosProgramados;
