/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import ResponsableForm from "./ResponsableForm";
import {Get} from "../../tools/Helper";
import Message from "../../tools/Message";

function Responsables() {

  const emptyForm = {
    nombre:'',
    correo:'',
    puesto:'',
    status:1,
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);

  const editarButton = (row) =>{
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const columns = [
    {
        name: 'Nombre',
        selector: row => row.nombre,
    },
    {
        name: 'Puesto',
        selector: row => row.puesto,
    },
    {
        name: 'Correo',
        selector: row => row.correo,
    },
    {
        name: '',
        selector: row => editButton(row),
        compact: true
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('responsables');

    if(request.status === "success"){
      setData(request.data);
      setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                         Catálogo Responsables
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton variant="gradient" color="light" 
                      onClick={()=>crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: 'Personas por página:', 
                        rangeSeparatorText: 'de', 
                        noRowsPerPage: false, 
                        selectAllRowsItem: true, 
                        selectAllRowsItemText: 'Todos'
                      }}
                  />}
      
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <ResponsableForm data={selectedData} editar={editar} setShow={setShow} getLista={getData} />
          </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Responsables;