/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import { Link, useParams } from "react-router-dom";

import Select from 'react-select';
import OssCard from "../../examples/Cards/InfoCards/OssCard";

import {Get, Post} from "../../tools/Helper";
import Message from "../../tools/Message";


function ResponderPregunta() {

  const inputFile = useRef(null);
  const params = useParams();

  const { columns: pColumns, rows: pRows } = projectsTableData();
  const emptyForm = {respuesta: '', evidencias: [], estatus: 0}
  const emptyFormEvidencia = {evidencia: '', comentario: '', descripcion: '', nombre: '', info:''}
  const [formData, setFormData] = useState([]);
  const [servicio, setServicio] = useState("");

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.name]:e.target.value});
  }

  const handleChangeRespuesta = (e, index) =>{
  	const tempArr = [...formData];
    if(tempArr[index].respuesta){
      tempArr[index].respuesta.respuesta = e.respuesta;
    }else{
      tempArr[index].respuesta = {respuesta: e.respuesta, evidencias: []}
    }
  	// tempArr[index].respuesta ? = e.respuesta;
    setFormData(tempArr);
  }

  const handleChangeEvidencia = (e, index, indexEv) =>{
    const tempArr = [...formData];
  	tempArr[index].respuesta.evidencias[indexEv][e.target.name] = e.target.value;
    setFormData(tempArr);
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [editar, setEditar] = useState(true);

  const editarButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const verButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(false);
  }

  const respuestasOpciones = [{respuesta:"Si"},{respuesta:"No"},{respuesta:"Otro"}];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});


  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`acciones-oss/${params.idOss}/servicio/${params.idServ}`);
    // const request = await Get('preguntas/59/1');

    if(request.status === "success"){
      setData(request.data);
      setServicio(request.servicio);
    }else{
      setStatus({status:request.status, message:"Error por favor intente de nuevo."});
    }
    setLoading(false);
  }

  const responder = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Post(`responder-oss/${params.idOss}/servicio/${params.idServ}`, {preguntas: formData});
    // const request = await Get('preguntas/59/1');
    setStatus(request);

    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  useEffect(()=>{
    /* if(data.length > 0){
    	let tempArr = [];
    	data.forEach(()=>{
    		tempArr = [...tempArr, {...emptyForm}]
    	});
    	setFormData(tempArr);
    	console.log("no");
    } */
    if(data.length > 0){
      const newArr = data.map((el)=> {
        if(el.respuesta){
          if(el.respuesta.length === 0){
            const tempArr = el;
            tempArr.respuesta = [{...emptyForm}]
            return tempArr;
          }
        }
      
        return el;
      });
      setFormData(newArr);
    }
    
  },[data]);

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const agregarEvidencia = (index) =>{
  	const tempArr = [...formData];
  	tempArr[index].respuesta.evidencias = [...tempArr[index].respuesta.evidencias, {...emptyFormEvidencia}];
  	setFormData(tempArr);
  }

  const borrarEvidencia = (index, ind) =>{
    const tempArr = [...formData];
    tempArr[index].respuesta.evidencias.splice(ind, 1);
    setFormData(tempArr);
  }

  const setFile = () => {
    // `current` points to the mounted file input element
   inputFile.current.click();
  };

  const descargarEvidencia = (index, ind) =>{
    const a = document.createElement("a"); // Create <a>
    a.href = formData[index].respuesta.evidencias[ind].evidencia; // Image Base64 Goes here
    a.download = formData[index].respuesta.evidencias[ind].nombre; // File name Here
    a.click();
  }

  /* eslint-disable no-param-reassign */
  const handleFile = (event, index, ind) => {

    setStatus({status: null, message: ""});
      const extension = event.target.files[0].type;
      // console.log(extension);

      /*
    if(extension !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      setStatus({status: 'danger', message:'La extension del archivo no coincide con una hoja de calculo Excel.'});
      event.target.value = null;
      return;
    } */
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
        // console.log(reader.result);
        const tempArr = [...formData];
        tempArr[index].respuesta.evidencias[ind] = 
          {...tempArr[index].respuesta.evidencias[ind],
          nombre: event.target.files[0].name,
          tipo: event.target.files[0].type,
          'evidencia': reader.result};
        setFormData(tempArr);
    };

  }
  /* eslint-disable no-param-reassign */

  const evidenciaForm = (index) => formData[index].respuesta.evidencias ? formData[index].respuesta.evidencias.map((evidencia, ind)=>
    	<>
        <hr/>
        <Grid item xs={12}>
          <MDTypography variant="h6" color="error">
            {verifyError(`preguntas.${index}.respuesta.evidencias.${ind}.nombre`) ? 
              "Debe subir un archivo. De lo contrario presione el boton de 'Borrar evidencia'" : 
              ""}
          </MDTypography>
          <MDTypography variant="h6" display="inline" pt={3}>Archivo: {evidencia.nombre !== "" ? evidencia.nombre : <input type='file' className="btn btn-primary"// style={{display: 'none'}}
                    onChange={(e)=>handleFile(e, index, ind)}
                    />}</MDTypography>
        </Grid>        
        	<Grid item xs={12} lg={4} mt="auto" textAlign="center">
            {
              evidencia.nombre === "" ? 
              /* <MDButton variant="gradient" color="info" disabled={loading} 
                 fullWidth>
                Agregar archivo
              </MDButton> */ 
          
              <Grid pr={4} container my="auto">
                <Grid item xs={12} pr={1}>
                  <MDButton variant="gradient" color="error" disabled={loading} onClick={()=>borrarEvidencia(index, ind)}
                    mx={4} fullWidth>
                    <Icon fontSize="small">delete_forever</Icon>
                    Borrar evidencia
                  </MDButton>
                </Grid>
              </Grid>
              :
              <Grid pr={4} container my="auto">
                <Grid item xs={6} pr={1}>
                  <MDButton variant="gradient" color="info" disabled={loading} onClick={()=>descargarEvidencia(index, ind)}
                    mx={4} fullWidth>
                    <Icon fontSize="small">download</Icon>
                    Descargar Evidencia
                  </MDButton>
                </Grid>
                <Grid item xs={6} pr={1}>
                  <MDButton variant="gradient" color="error" disabled={loading} onClick={()=>borrarEvidencia(index, ind)}
                    mx={4} fullWidth>
                    <Icon fontSize="small">delete_forever</Icon>
                    Borrar evidencia
                  </MDButton>
                </Grid>
              </Grid>
            }
            </Grid>
            <Grid item xs={12} lg={8} mt="auto">
            	<MDInput type="text" label="Descripcion" name="descripcion"
                  error={verifyError(`preguntas.${index}.respuesta.evidencias.${ind}.descripcion`)} 
                  errorMsg={getError(`preguntas.${index}.respuesta.evidencias.${ind}.descripcion`)}
                  value={formData[index].respuesta.evidencias[ind].descripcion} onChange={event => handleChangeEvidencia(event, index, ind)} fullWidth />
            </Grid>
            {/* <Grid item xs={6} lg={4} mt="auto">
            	<MDInput type="text" label="Comentario" name="comentario"
                  error={verifyError(`preguntas.${index}.respuesta.evidencias.${ind}.comentario`)} 
                  errorMsg={getError(`preguntas.${index}.respuesta.evidencias.${ind}.comentario`)}
                  value={formData[index].respuesta.evidencias[ind].comentario} onChange={event => handleChangeEvidencia(event, index, ind)} fullWidth />
            </Grid> */}
        </>
	) : "";

  const preguntasForm = () => data.map((pregunta, index)=> 
		formData.length > 0 ? 
    <MDBox py={1}>
    <Card borderWidth={1} borderColor="info" className="border1">
    <MDBox pb={4} px={4}>
    <Grid container spacing={2}>
      <Grid item xs={9} my="auto">
        <MDTypography variant="h6" pt={3}>{pregunta.descripcion}</MDTypography>
        <Select className="z-auto"
              options={respuestasOpciones} placeholder="Seleccione una opcion..."
              value={
                formData[index].respuesta === null ? "" : {respuesta:formData[index].respuesta.respuesta}
              } onChange={(e) => handleChangeRespuesta(e,index)}
              getOptionValue={(option) => `${option.respuesta}`}
              getOptionLabel={(option) => `${option.respuesta}`}
            />
      </Grid>
      <Grid item xs={3} mt="auto">
        <MDTypography variant="h6" pt={3}>Evidencias</MDTypography>
        <MDButton variant="gradient" color="info" disabled={loading}
             fullWidth onClick={()=>agregarEvidencia(index)}>
            Agregar
          </MDButton>
      </Grid>
      {formData[index].respuesta ? evidenciaForm(index) : ""}
      
  </Grid></MDBox></Card></MDBox> : "");

  const boton = () => data.length > 0 ? 
  <MDButton variant="gradient" color="info" mt={2}
     fullWidth onClick={()=>responder()}>
    Subir Respuestas y Evidencias 
  </MDButton> : "No se han definido acciones para el servicio.";

  return (
    <DashboardLayout>
      <DashboardNavbar />
       
      <MDBox pt={6} pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Responder Acciones del servicio
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <Link to="/mis-oss">
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                  </Link>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox py={3} px={4}>
                <Message status={status} />
                {loading ? 
                  <MDBox item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  : 
              
                    <MDBox pb={2}>
                      <MDTypography variant="h4" pt={3}>Servicio: {servicio.descripcion}</MDTypography>
                      <MDTypography variant="h5" pt={3}>Acciones</MDTypography>
                      {preguntasForm()}
                    </MDBox>
                    
                    
                    }
                    {loading ? "" : boton()}
              
            </MDBox>
          </Card>
          
        </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ResponderPregunta;
