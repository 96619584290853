/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import Icon from "@mui/material/Icon";

import ClienteForm from "../catalogo/ClienteForm";
import {Get} from "../../tools/Helper";
import BoxLinksRegister from "../../components/BoxLinksRegister";
import Message from "../../tools/Message";

function ClienteExterno() {

  const emptyForm = {
    logo_cliente: '',
    nombre_comercial:'',
    razon_social: '',
    calle_num_inmueble: '',
    municipio_inmueble: '',
    colonia_inmueble: '',
    estado_inmueble: '',
    calle_num_fiscal: '',
    municipio_fiscal: '',
    colonia_fiscal: '',
    estado_fiscal: '',
    persona_contacto: '',
    telefono: '',
    correo: '',
    status: 1,
    rfc: '',
    nombre_patron: '',
    nombre_representante: '',
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);

  const editarButton = (row) =>{
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const columns = [
    {
        name: 'Nombre Comercial',
        selector: row => row.nombre_comercial,
    },
    {
        name: 'Razon Social',
        selector: row => row.razon_social,
    },
    {
        name: 'Persona de Contacto',
        selector: row => row.persona_contacto,
    },
    {
        name: 'Telefono',
        selector: row => row.telefono,
    },
    {
        name: 'Correo',
        selector: row => row.correo,
    },
    {
        name: '',
        selector: row => editButton(row),
        compact: true
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));

    const request = await Get(`cliente-externo/${user.user.id_cliente}`);

    if(request.status === "success"){
      setData(request.data);
      if(!request.data[0].rfc || request.data[0].rfc === ''){
        setStatus({status: "warning", message: "Por favor complete sus datos."});
      }
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    
    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const tableData = {
    columns,
    data,
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={12} className="headerShareLink">
                  <Grid item md={6} className="headerTitleShareLink" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                         Mi perfil de empresa
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <>
                    <Message status={status} />
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Clientes por página:', 
                          rangeSeparatorText: 'de', 
                          noRowsPerPage: false, 
                          selectAllRowsItem: true, 
                          selectAllRowsItemText: 'Todos'
                        }}
                    />
                    </DataTableExtensions>
                  </>
                  }
      
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <ClienteForm data={selectedData} editar={editar} setShow={setShow} getLista={getData} />
          </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClienteExterno;