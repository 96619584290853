/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import QRCode from 'qrcode';

import Icon from "@mui/material/Icon";
import { Document, PDFDownloadLink, pdf } from '@react-pdf/renderer'; 

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';

import domtoimage from 'dom-to-image';

import backImg from "../../tools/credencial_fondo.jpg";
import frame from "../../tools/qr.png";
import NoImg from "../../tools/no-img.png"; 

import canvasPreview from './canvasPreview'
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from './useDebounceEffect';

import PDFGenerator from "../../tools/PDFGenerator";
import FondoCred from '../../tools/credencial_fondo.png';
import NewCertGenerator from "../../tools/NewCertGenerator";
import CertGeneratorPDF from "../../tools/CertGeneratorPDF";

import {Get, Post, PostMulti} from "../../tools/Helper";

import Message from "../../tools/Message";

function Tables() {

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;

  const emptyForm = {
    nombre_cursante: '',
    curp_cursante: '',
    nombre_curso: '',
    duracion_curso: '',
    fecha_imparticion_curso: datestring,
    lugar: '',
    id_ocupacion: null,
    puesto: '',
    fecha_termino: datestring,
    vigencia_str: null,
    vigencia_fecha: datestring,
    area: '',
    id_cursante: '',
    evidencias: {
      curp: '',
      curp_nombre: '',
      foto: '',
      foto_nombre: '',
      ine: '',
      ine_nombre: '',
    },
    cursante:{
      curp: "",
      puesto: "",
      id_ocupacion: "",
      ocupacion: {
        nombre: ""
      },
      nombre_completo: "",
    }
  };

  const newForm = {
    nombre_cursante: '',
    curp_cursante: '',
    evidencias: {
      curp: '',
      curp_nombre: '',
      foto: '',
      foto_nombre: '',
      ine: '',
      ine_nombre: '',
    },
    id_ocupacion: null,
    puesto: '',
  };

  const [formData, setFormData] = useState(emptyForm);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  } 

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const [editarFoto, setEditarFoto] = useState(false)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 12)

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        const res = reader.result?.toString() || '';
        setImgSrc(res);
        setFormData({...formData, evidencias:{...formData.evidencias, foto: res, foto_nombre: e.target.files[0].name}});
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        )
      }
    },
    100,
    [completedCrop, scale, 0],
  );

  const scrollRef = useRef(React.createRef());

  const animatedComponents = makeAnimated();

  const [instructores, setInstructores] = useState([]);
  const [administradores, setAdministradores] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [ocupaciones, setOcupaciones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [cursantes, setCursantes] = useState([]);
  const [pdfData, setPdfData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const [dataFinal, setDataFinal] = useState(null);
  const [qr, setQr] = useState(null);

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('catalogos-cursos');

    if(request.status === "success"){
      setInstructores(request.instructores);
      setAdministradores(request.administradores);
      setCursos(request.cursos);
      setClientes(request.clientes);
      setOcupaciones(request.ocupaciones);
      setCursantes(request.cursantes);
    }else{
      setStatus(request);
    }

    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else if(name === "curso"){
      setFormData({
        ...formData, 
        [value]:e[value], 
        [name]:e, 
        nombre_curso:e.nombre, 
        duracion_curso:e.duracion_horas
      });
    }else if(name === "instructor"){
      setFormData({
        ...formData, 
        [value]:e.id_firmante, 
        [name]:e, 
        nombre_instructor:e.nombre_completo, 
        firma_instructor:e.firma
      });
    }else if(name === "administrador"){
      setFormData({
        ...formData, 
        [value]:e.id_firmante, 
        [name]:e, 
        nombre_administrador:e.nombre_completo, 
        firma_administrador:e.firma
      });
    }else{
      setFormData({...formData, [value]:e[value], [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const submitCert = async() =>{
    const tempData = formData;
    setStatus({status: 'warning', message: "Guardando...."});
    setLoading(true);

    if(completedCrop){
      const type = tempData.evidencias.foto.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      tempData.evidencias.foto = await previewCanvasRef.current.toDataURL(type);
    }

    tempData.nombre_cursante = tempData.cursante.nombre_completo;
    tempData.curp_cursante = tempData.cursante.curp;
    tempData.puesto = tempData.cursante.puesto;
    tempData.id_ocupacion = tempData.cursante.id_ocupacion;
    const request = await Post('certificados',tempData) ;

    if(request.status === "success"){

      const qrData = await QRCode.toDataURL(`https://certificados.paymun.adaptingweb.com/${request.data.folio_unico_registro}/${request.data.token_certificado}`);
      await setQr(qrData);  
      await setDataFinal(request.data);
      const doc = 
      <Document>
        <CertGeneratorPDF data={request.data} qr={qrData} />
        <NewCertGenerator data={request.data} qr={qrData} />
      </Document>;

      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      const credencial = await domtoimage.toBlob(document.getElementById('canv'));

      const formData2 = new FormData();
      formData2.append("certificado", blob);
      formData2.append("credencial", credencial);

      formData2.append("id_certificado", request.data.id_certificado);

      const request2 = await PostMulti('email-certificado',formData2) ;
      
    }

    if(request.status === "success"){
      setFormData({...formData, ...newForm});
      setPdfData(request.data);
      setScale(0.9);
      setCompletedCrop(undefined);
    }

    setStatus(request);

    if(scrollRef.current){
      scrollRef.current.scrollIntoView({ 
           behavior: "smooth", 
        });
    }

    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const getCurso = cursos.find(option => option.id_curso === formData.id_curso);
  const getCliente = clientes.find(option => option.id_cliente === formData.id_cliente);
  const getInstructor = instructores.find(option => option.id_firmante === formData.id_instructor);
  const getAdministrador = administradores.find(option => option.id_firmante === formData.id_administrador);
  const getOcupacion = ocupaciones.find(option => option.id_ocupacion === formData.id_ocupacion);
  const getCursante = cursantes.find(option => parseInt(option.id_cursante,10) === parseInt(formData.id_cursante,10));

  const downloadPDF = async() => { // prevent page reload
    const qr2 = await QRCode.toDataURL(`https://certificados.paymun.adaptingweb.com/${pdfData.folio_unico_registro}/${pdfData.token_certificado}`);

    const blob = await pdf(
        <CertGeneratorPDF data={pdfData} qr={qr2} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `certificado_${pdfData.nombre_cursante}.pdf`;
    link.click();
  }

  const handleFile = (event, name) => {

    const extension = event.target.files[0].type;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
        setFormData({...formData, evidencias:{...formData.evidencias, [name]: reader.result, [`${name}_nombre`]: event.target.files[0].name}});
    };

  }

  const borrarEvidencia = (name) => {
    setFormData({...formData, evidencias:{...formData.evidencias, [name]: '', [`${name}_nombre`]: ''}});
  }

  const descargarEvidencia = (name) =>{
    const a = document.createElement("a"); // Create <a>
    a.href = formData.evidencias[name]; // Image Base64 Goes here
    a.download = formData.evidencias[`${name}_nombre`]; // File name Here
    a.click();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Capturar nuevo certificado
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                <Link to="/certificados/lista">
                  <MDButton variant="gradient" color="light">
                    Lista de certificados
                  </MDButton>
                </Link>
                </Grid>
              </Grid>
            </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Message status={status} />
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="h5">
                          Participante
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" className="z-101">
                        <MDTypography variant="caption" pt={3} pr={3}>Nombre</MDTypography>
                        <Select className={`z-101 ${verifyError("id_cursante") ? "border-red" : ""}`}
                          options={cursantes} placeholder="Cliente"
                          value={getCursante} onChange={(e) => handleChange(e,"cursante","id_cursante")}
                          getOptionValue={(option) => `${option.id_cursante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="text" label="CURP" name="curp_cursante"
                          error={verifyError("curp_cursante")} errorMsg={getError("curp_cursante")}
                          value={formData.cursante.curp} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" className="z-9">
                        <MDInput type="text" label="Ocupación" name="ocupacion"
                          error={verifyError("ocupacion")} errorMsg={getError("ocupacion")}
                          value={formData.cursante.ocupacion.nombre} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="text" label="Puesto" name="puesto"
                          error={verifyError("puesto")} errorMsg={getError("puesto")}
                          value={formData.cursante.puesto} onChange={handleChange} fullWidth disabled />
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="h5">
                          Datos del curso
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={4} className="z-8">
                        <Select className={`z-10 ${verifyError("id_curso") ? "border-red" : ""}`}
                          options={cursos} placeholder="Seleccione un curso"
                          value={getCurso} onChange={(e) => handleChange(e,"curso","id_curso")}
                          getOptionValue={(option) => `${option.id_curso}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="text" label="Duracion (Horas)" name="duracion_curso"
                          error={verifyError("duracion_curso")} errorMsg={getError("duracion_curso")}
                          value={formData.duracion_curso} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="text" label="Lugar" name="lugar"
                          error={verifyError("lugar")} errorMsg={getError("lugar")}
                          value={formData.lugar} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="date" label="Fecha inicio" name="fecha_imparticion_curso"
                          error={verifyError("fecha_imparticion_curso")} errorMsg={getError("fecha_imparticion_curso")}
                          value={formData.fecha_imparticion_curso} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="date" label="Fecha término" name="fecha_termino"
                          error={verifyError("fecha_termino")} errorMsg={getError("fecha_termino")}
                          value={formData.fecha_termino} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto">
                        <MDInput type="date" label="Fecha expiración" name="vigencia_fecha"
                          error={verifyError("vigencia_fecha")} errorMsg={getError("vigencia_fecha")}
                          value={formData.vigencia_fecha} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4} mt={-1} className="z-7">
                        <MDTypography variant="caption" pt={3} pr={3}>Instructor</MDTypography>
                        <Select className={`z-10 ${verifyError("id_instructor") ? "border-red" : ""}`}
                          options={instructores} placeholder="Instructor"
                          value={getInstructor} onChange={(e) => handleChange(e,"instructor","id_instructor")}
                          getOptionValue={(option) => `${option.id_firmante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} mt={-1} className="z-7">
                        <MDTypography variant="caption" pt={3} pr={3}>Administrador</MDTypography>
                        <Select className={`z-10 ${verifyError("id_administrador") ? "border-red" : ""}`}
                          options={administradores} placeholder="Administrador"
                          value={getAdministrador} onChange={(e) => handleChange(e,"administrador","id_administrador")}
                          getOptionValue={(option) => `${option.id_firmante}`}
                          getOptionLabel={(option) => `${option.nombre_completo}`}
                        />
                      </Grid>
                    </Grid>
                    <div className="cont cont-hide">
                      { dataFinal && qr ? <div className="pos-rel border1" id="canv">
                          <img
                            alt="bg"
                            src={backImg}
                            className="pos-rel"
                          />
                          <img
                            alt="foto"
                            src={dataFinal.cursante.evidencias && dataFinal.cursante.evidencias.foto ? dataFinal.cursante.evidencias.foto : NoImg}
                            className="avatar"
                          />
                          <img src={qr} alt="qr" className="qr" />
                          <div className="nombre-acreditado">
                            Nombre del acreditado
                            <br />
                            <span className="bold">{ dataFinal.nombre_cursante }</span>
                          </div>
                          <div className="curso">
                            Curso:
                            <br />
                            <span className="bold">{ dataFinal.curso.nombre }</span>
                          </div>
                          <div className="fecha-finalizacion">
                            Fecha de finalización:
                            <br />
                            <span className="bold">{ dataFinal.fecha_termino }</span>
                          </div>
                          <div className="no-certificacion">
                            No. de certificación:
                            <br />
                            <span className="bold">{ dataFinal.folio_unico_registro }</span>
                          </div>
                          <div className="desarrollo">Desarrollo de Talento</div>
                          <div className="humano">Humano</div>
                        </div> : "Cargando..."}
                    </div>
                    <Message status={status} />
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" 
                        onClick={() => submitCert()} fullWidth>
                        Generar y enviar documentos de acreditación
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
