import React, { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import Select from 'react-select';

import PropTypes from 'prop-types';

import {Get} from "../../tools/Helper";

import Message from "../../tools/Message";

function Detalles({ editing, dataCursoProgramado, setDataCursoProgramado }) {


  const [instructores, setInstructores] = useState([]);
  const [administradores, setAdministradores] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [clientes, setClientes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('catalogos-cursos');

    if(request.status === "success"){
      setInstructores(request.instructores);
      setAdministradores(request.administradores);
      setCursos(request.cursos);
      setClientes(request.clientes);
      
      setDataCursoProgramado({
        ...dataCursoProgramado,
        cursantes: request.cursantes
      })
    }else{
      setStatus(request);
    }

    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const handleChange = (e, name = "", value = "") =>{
    console.log(name)
    if(name === ""){
      setDataCursoProgramado({...dataCursoProgramado, [e.target.name]:e.target.value});
    }else if(name === "curso"){
      setDataCursoProgramado({
        ...dataCursoProgramado, 
        [value]:e[value], 
        [name]:e, 
        nombre_curso:e.nombre, 
        duracion_curso:e.duracion_horas
      });
    }else if(name === "cliente"){
      setDataCursoProgramado({
        ...dataCursoProgramado, 
        [value]:e[value], 
        [name]:e, 
        calle_numero: e.calle_num_inmueble,
        colonia: e.colonia_inmueble,
        municipio_alcaldia: e.municipio_inmueble,
        estado: e.estado_inmueble,
      });
    }else if(name === "instructor"){
      setDataCursoProgramado({
        ...dataCursoProgramado, 
        [value]:e.id_firmante, 
        [name]:e, 
        nombre_instructor:e.nombre_completo, 
        firma_instructor:e.firma
      });
    }else if(name === "administrador"){
      setDataCursoProgramado({
        ...dataCursoProgramado, 
        [value]:e.id_firmante, 
        [name]:e, 
        nombre_administrador:e.nombre_completo, 
        firma_administrador:e.firma
      });
    }else{
      setDataCursoProgramado({...dataCursoProgramado, [value]:e[value], [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const getCurso = cursos.find(option => option.id_curso === parseInt(dataCursoProgramado.id_curso,10));
  const getCliente = clientes.find(option => option.id_cliente === parseInt(dataCursoProgramado.id_cliente,10));
  const getInstructor = instructores.find(option => option.id_firmante === parseInt(dataCursoProgramado.id_instructor,10));
  const getAdministrador = administradores.find(option => option.id_firmante === parseInt(dataCursoProgramado.id_administrador,10));

  const programaInterno = [
    { respuesta: 'SI'},
    { respuesta: 'NO'},
  ]
  const getPrograma = programaInterno.find(option => option.respuesta === dataCursoProgramado.respuesta);

  return (
  <MDBox>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Message status={status} />
      </Grid>
      <Grid item xs={12} md={4} mt="auto"  style={{ zIndex: 30 }}>
        <MDTypography variant="caption" pt={3} pr={3}>Nombre Empresa</MDTypography>
        <Select className={`z-101 ${verifyError("id_cliente") ? "border-red" : ""}`}
          options={clientes} placeholder="Empresa"
          value={getCliente} onChange={(e) => handleChange(e,"cliente","id_cliente")}
          getOptionValue={(option) => `${option.id_cliente}`}
          getOptionLabel={(option) => `${option.nombre_comercial}`}
          isDisabled = {!editing}
        />
      </Grid>
      <Grid item xs={12} md={4} mt="auto"  style={{ zIndex: 20 }}>
        <MDTypography variant="caption" pt={3} pr={3}>Programa interno de protección civil</MDTypography>
        <Select className={`z-101 ${verifyError("respuesta") ? "border-red" : ""}`}
          options={programaInterno} placeholder="Programa Interno
          "
          value={getPrograma} onChange={(e) => handleChange(e,"programa","respuesta")}
          getOptionValue={(option) => `${option.respuesta}`}
          getOptionLabel={(option) => `${option.respuesta}`}
          isDisabled = {!editing}
        />
      </Grid>
      <Grid item xs={12}>
        <MDTypography variant="h5">
          Datos del curso
        </MDTypography>
      </Grid>
      <Grid item xs={12} md={4} className="z-8">
        <Select className={`z-10 ${verifyError("id_curso") ? "border-red" : ""}`}
          options={cursos} placeholder="Seleccione un curso"
          value={getCurso} onChange={(e) => handleChange(e,"curso","id_curso")}
          getOptionValue={(option) => `${option.id_curso}`}
          getOptionLabel={(option) => `${option.nombre}`}
          isDisabled = {!editing}
        />
      </Grid>
      <Grid item xs={12} md={4} mt="auto">
        <MDInput type="text" label="Duracion (Horas)" name="duracion_curso"
          error={verifyError("duracion_curso")} errorMsg={getError("duracion_curso")}
          value={dataCursoProgramado.duracion_curso} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={4} mt="auto">
        <MDInput type="text" label="No. Participantes" name="num_participantes"
          error={verifyError("num_participantes")} errorMsg={getError("num_participantes")}
          value={dataCursoProgramado.num_participantes} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={4} mt="auto">
      <MDTypography variant="caption" pt={3} pr={3}>Fecha Inicio</MDTypography>
        <MDInput type="date" label="" name="fecha_imparticion_curso"
          error={verifyError("fecha_imparticion_curso")} errorMsg={getError("fecha_imparticion_curso")}
          value={dataCursoProgramado.fecha_imparticion_curso} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={4} mt="auto">
        <MDTypography variant="caption" pt={3} pr={3}>Fecha Termino</MDTypography>
        <MDInput type="date" label="" name="fecha_termino"
          error={verifyError("fecha_termino")} errorMsg={getError("fecha_termino")}
          value={dataCursoProgramado.fecha_termino} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={4} mt="auto">
        <MDTypography variant="caption" pt={3} pr={3}>Fecha Expiración</MDTypography>
        <MDInput type="date" label="" name="vigencia_fecha"
          error={verifyError("vigencia_fecha")} errorMsg={getError("vigencia_fecha")}
          value={dataCursoProgramado.vigencia_fecha} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={4} mt={-1} className="z-7">
        <MDTypography variant="caption" pt={3} pr={3}>Firma Izquierda</MDTypography>
        <Select className={`z-10 ${verifyError("id_instructor") ? "border-red" : ""}`}
          options={instructores} placeholder="Instructor"
          value={getInstructor} onChange={(e) => handleChange(e,"instructor","id_instructor")}
          getOptionValue={(option) => `${option.id_firmante}`}
          getOptionLabel={(option) => `${option.nombre_completo}`}
          isDisabled = {!editing}
        />
      </Grid>
      <Grid item xs={12} md={4} mt={-1} className="z-7">
        <MDTypography variant="caption" pt={3} pr={3}>Firma Derecha</MDTypography>
        <Select className={`z-10 ${verifyError("id_administrador") ? "border-red" : ""}`}
          options={administradores} placeholder="Administrador"
          value={getAdministrador} onChange={(e) => handleChange(e,"administrador","id_administrador")}
          getOptionValue={(option) => `${option.id_firmante}`}
          getOptionLabel={(option) => `${option.nombre_completo}`}
          isDisabled = {!editing}
        />
      </Grid>
    </Grid>
  </MDBox>
  );
}

Detalles.propTypes = {
  dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  editing: PropTypes.bool.isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
};

export default Detalles;
