import React, { useState } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import PropTypes from 'prop-types';

import Message from "../../tools/Message";

function Direccion({ editing, dataCursoProgramado, setDataCursoProgramado }) {
  const [status, setStatus] = useState({status: null, message: ""});

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
        setDataCursoProgramado({...dataCursoProgramado, [e.target.name]:e.target.value});
      }else{
        setDataCursoProgramado({...dataCursoProgramado, [value]:e[value], [name]:e});
      }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const containerStyle = {
    width: '300px',
    height: '300px'
  };

  return (
  <MDBox>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Message status={status} />
      </Grid>
      <Grid item xs={12} md={6} mt="auto">
        <MDInput type="text" label="Calle y Número" name="calle_numero"
          error={verifyError("calle_numero")} errorMsg={getError("calle_numero")}
          value={dataCursoProgramado.calle_numero} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={6} mt="auto">
        <MDInput type="text" label="Colonia" name="colonia"
          error={verifyError("colonia")} errorMsg={getError("colonia")}
          value={dataCursoProgramado.colonia} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={6} mt="auto">
        <MDInput type="text" label="Municipio/Alcaldía" name="municipio_alcaldia"
          error={verifyError("municipio_alcaldia")} errorMsg={getError("municipio_alcaldia")}
          value={dataCursoProgramado.municipio_alcaldia} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={6} mt="auto">
        <MDInput type="text" label="Estado" name="estado"
          error={verifyError("estado")} errorMsg={getError("estado")}
          value={dataCursoProgramado.estado} onChange={handleChange} fullWidth 
          disabled = {!editing}
          />
      </Grid>
      <Grid item xs={12} md={6} mt="auto">
        {
            dataCursoProgramado.latitude ?
                <LoadScript googleMapsApiKey="AIzaSyDtEyz57YMYuFoIn5CGFBxsGWXJwBDUpXY">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ 
                            lat: dataCursoProgramado.latitude ? parseFloat(dataCursoProgramado.latitude) : null, 
                            lng: dataCursoProgramado.longitude  ? parseFloat(dataCursoProgramado.longitude)  : null }}
                        zoom={11}
                    >
                        <Marker position={{ 
                            lat: dataCursoProgramado.latitude ? parseFloat(dataCursoProgramado.latitude) : null, 
                            lng: dataCursoProgramado.longitude  ? parseFloat(dataCursoProgramado.longitude)  : null
                        }} />
                    </GoogleMap>
                </LoadScript>
            : null
        }
      </Grid>
    </Grid>
  </MDBox>
  );
}

Direccion.propTypes = {
  dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  editing: PropTypes.bool.isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
};

export default Direccion;
