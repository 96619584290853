/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import Modal from 'react-modal';
import UserForm from "./UserForm";
import {Get,Del} from "../../tools/Helper";
import Message from "../../tools/Message";

function Usuario() {

  const emptyForm = {
    name:'',
    email: '',
    password: '',
    responsable_id: '',
    responsable: [],
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [responsables, setResponsables] = useState([]);
  const [responsablesAll, setResponsablesAll] = useState([]);

  const editarButton = (row) =>{
    let newData = {};

    if(row.responsable){
      newData = {
        ...row,
        id_res: `res-${row.responsable.id_responsable}`,
        responsable: {  
          id_res:  `res-${row.responsable.id_responsable}`,
          nombre: row.responsable.nombre
        }
      }
    }

    if(row.firmante){
      newData = {
        ...row,
        id_res:  `ins-${row.firmante.id_firmante}`,
        responsable: {  
          id_res:  `ins-${row.firmante.id_firmante}`,
          nombre: row.firmante.nombre_completo
        }
      }
    }
    
    setSelectedData(newData);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const conditionalRowStyles = [
    {
      when: row => row.activo === 0,
      style: row => ({ backgroundColor: '#ebc5c5' }),
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('users');

    if(request.status === "success"){
      const newListUsers = request.data.filter(item=> item.id_responsable !== null || item.id_firmante !== null);
      setData(newListUsers);
      setResponsables(request.responsables);
      const newResponsable = [];
      request.responsables.forEach(item=>{
        newResponsable.push({
            id_res: `res-${item.id_responsable}`,
            nombre: `${item.nombre} (OSS)`
        })
      })
      request.instructores.forEach(item=>{
        newResponsable.push({
            id_res: `ins-${item.id_firmante}`,
            nombre: `${item.nombre_completo} (Instructor)`
        })
      })
      setResponsablesAll(newResponsable);
      setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    
    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState({id:0,name:"",activo:1});

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete({id:0,name:"",activo:1});
  }

  function openModal(row) {
    setIsOpen(true);
    console.log(row);
    setSelectedDelete({id: row.id, nombre: row.name, activo: row.activo});
  }

  const deleteUser = async () =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`users/${selectedDelete.id}`);

    if(request.status === "success"){
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const deleteButton = (row) => <MDButton variant="gradient" 
    color="error" iconOnly onClick={()=>openModal(row)}><Icon>not_interested</Icon></MDButton>;

  const activateButton = (row) => <MDButton variant="gradient" 
    color="success" iconOnly onClick={()=>openModal(row)}><Icon>check_box</Icon></MDButton>;

  const resposableInstructor = (row) =>{
    let responsable = '';
    if(row.responsable){
      responsable = row.responsable.nombre
    }

    if(row.firmante){
      responsable = row.firmante.nombre_completo
    }

    return responsable;
  }

  const puestoResponsable = (row) =>{
    let puesto = '';
    if(row.responsable){
      puesto = row.responsable.puesto
    }

    if(row.firmante){
      puesto = row.firmante.puesto
    }

    return puesto;
  }

  const columns = [
    {
        name: 'Alias (Nombre)',
        selector: row => row.name,
    },
    {
        name: 'Correo',
        selector: row => row.email,
    },
    {
        name: 'Responsable',
        selector: row => resposableInstructor(row),
    },
    {
        name: 'Puesto',
        selector: row => puestoResponsable(row),
    },
    {
        name: 'Permiso',
        selector: row => row.is_admin.nombre,
    },
    {
        name: '',
        selector: row => editButton(row),
        compact: true
    },
    {
        name: '',
        selector: row => parseInt(row.activo,10) === 1 ? deleteButton(row) : activateButton(row),
        compact: true
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                        Usuarios internos
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton variant="gradient" color="light" 
                      onClick={()=>crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: 'Clientes por página:', 
                        rangeSeparatorText: 'de', 
                        noRowsPerPage: false, 
                        selectAllRowsItem: true, 
                        selectAllRowsItemText: 'Todos'
                      }}
                      conditionalRowStyles={conditionalRowStyles}
                  />}
      
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <UserForm data={selectedData} editar={editar} setShow={setShow} getLista={getData} responsablesArr={responsablesAll} />
          </Collapse>
        </Fade>
      </MDBox>
      <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea eliminar al usuario ${selectedDelete.nombre}?`}
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <Grid item xs={12} pb={2}>
              <MDTypography variant="h2">¿Seguro que desea {selectedDelete.activo === 1 ? "desactivar" : "reactivar"} el usuario {selectedDelete.nombre}?</MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={()=>closeModal()} fullWidth>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>deleteUser()} fullWidth>
                Cambiar status
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Usuario;