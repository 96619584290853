/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

import PropTypes from 'prop-types';
import Select from 'react-select';

import { parseCurrency } from "tools/utils";


import {Get} from "../../tools/Helper";
import Message from "../../tools/Message";

function CotPreview({data, setShow, getLista, show}) {

  const scrollRef = useRef(React.createRef());

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;


	const [formData, setFormData] = useState({
	    numero:'',
	    num_cotizacion:'',
	    fecha_oss:datestring,
	    elabora:'',
	    elabora_puesto:'',
	    acepta:'',
	    responsables: undefined,
	    info_adicional:'',
	    comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
	    direccion:'',
      estado:'',
      municipio:'',
      type: 'cotizacion',
      comentarios0:0,
      comentarios1:0,
      comentarios2:0,
      condiciones0:0,
      condiciones1:0,
      condiciones2:0,
      otra_condicion: '',
      otro_comentario: '',
    });
    
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [usuariosArray, setUsuariosArray] = useState([]);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [condicionesArray, setCondicionesArray] = useState([]);
  const [comentariosArray, setComentariosArray] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [condiciones, setCondiciones] = useState([]);
  const [comentarios, setComentarios] = useState([]);


  const getDataCatalogos = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('catalogos');

    if(request.status === "success"){
      setServicios(request.servicios);
      setCondiciones(request.condiciones_comerciales);
      setComentarios(request.comentarios_adicionales);
    }else{
      setStatus(request);
    }

  }

  const getSumPriceUnit = (dataServicios, descuento = null) => {
    const sumPUnit = dataServicios.reduce((acumulador, elemento) => {
      const pUnit = parseFloat(elemento.servicio.p_unit);
      const importe = pUnit * parseInt(elemento.cantidad, 10)
      return acumulador + importe;
  }, 0);
  
    const totalDescuento = descuento ?? formData.descuento;
    setFormData({
      ...formData,
      descuento: totalDescuento,
      subtotal: (sumPUnit).toFixed(2),
      iva: (parseFloat((sumPUnit - totalDescuento).toFixed(2)) * 0.16).toFixed(2),
      total: (parseFloat((sumPUnit).toFixed(2)) * 1.16).toFixed(2),
      total_descuento: (parseFloat((sumPUnit - totalDescuento).toFixed(2)) * 1.16).toFixed(2),
    })
  };

	useEffect(()=>{
    setFormData(data);
    getDataCatalogos();
    if(data.servicios){
    	setServiciosArray(data.servicios);
    }
    if(data.condiciones){
    	setCondicionesArray(data.condiciones);
    }
    if(data.comentarios){
    	setComentariosArray(data.comentarios);
    }

    setUsuariosArray(data.usuarios);
    setStatus({status: null, message: ""});
  },[data,setFormData]);

	const [clientes, setClientes] = useState([]);
  const [responsables, setResponsables] = useState([]);

  const [catalogosJson, setCatalogosJson] = useState("");


  const setServArr = (d) =>{
    setServiciosArray(d);
    getSumPriceUnit(d);
  }

  useEffect(()=>{
  	const getData = async e =>{
    
	    setStatus({status: null, message: ""});
	    setLoading(true);

	    const request = await Get('catalogos');

	    if(request.status === "success"){
	      setClientes(request.clientes);
	      setResponsables(request.responsables);
        setCatalogosJson(JSON.stringify(request));
	    }else{
	      setStatus({status:request.status, message:"Error."});
	    }

	    setLoading(false);
	  }

    getData();
  },[]);

  const getVendedor = usuariosArray.find(option => option.name === formData.nombre_vendedor);
  const getComentarios = (index) => comentarios.length > 0 ? comentarios[index].comentarios.find(option => option.id_comentario_adicional === formData[`comentarios${index}`]) : null ;
  const getCondiciones = (index) => condiciones.length > 0 ? condiciones[index].condiciones.find(option => option.id_condicion_comercial === formData[`condiciones${index}`]) : null ;


	return (
        <MDBox pb={3} ref={scrollRef}>
          <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Cotizacion N° {formData.num_cotizacion}
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" />
              </Grid>
            </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Message status={status} />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="No. Cotización" name="num_cotizacion" 
                            value={formData.num_cotizacion} fullWidth disabled/>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="date" label="Fecha" name="fecha_cotizacion"
                            value={formData.fecha_cotizacion} fullWidth disabled/>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 9 }}>
                        <MDInput  type="number" label="Vigencia (días)" name="vigencia" 
                          value={formData.vigencia} disabled/>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          <MDTypography variant="h5" pb={1}>
                            Cliente
                          </MDTypography>
                          <Select className='z-10'
                            isDisabled
                            options={clientes} placeholder="Cliente..."
                            value={formData.cliente}
                            getOptionValue={(option) => `${option.id_cliente}`}
                            getOptionLabel={(option) => `${option.nombre_comercial}`}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider />
                    {
                      serviciosArray ?
                      serviciosArray.map(item=>(
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <MDBox mb={2}>
                              <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center'}}>
                                {item.cantidad}
                              </p>
                            </MDBox>
                          </Grid>
                          <Grid item xs={8}>
                            <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5}}>
                              {item.servicio.descripcion} 
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                              {parseCurrency((parseFloat(item.servicio.p_unit) * parseInt(item.cantidad, 10)).toFixed(2))} 
                            </p>
                          </Grid>
                      </Grid>
                      ))
                      : null
                    }
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                Subtotal
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="p" pb={1}>
                                {parseCurrency(formData.subtotal)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                Descuento
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                            <MDTypography variant="p" pb={1}>
                                {parseCurrency(formData.descuento)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                I.V.A.
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="p" pb={1}>
                                {parseCurrency(formData.iva)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="h5" pb={1}>
                                Total
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="h5" pb={1}  >
                                {parseCurrency(formData.total_descuento)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid md={6}>
                            <MDBox mb={2}>
                            <MDTypography variant="h5" fontSize={14} pb={0} >
                              Vendedor
                            </MDTypography>
                              <Select className='z-10'
                                isDisabled
                                options={usuariosArray} placeholder={getVendedor}
                                value={getVendedor}
                                getOptionValue={(option) => `${option.name}`}
                                getOptionLabel={(option) => `${option.name}`}
                              />
                              </MDBox>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h5" fontSize={14} pb={0} >
                                    Comentarios adicionales
                                  </MDTypography>
                                  {
                                    comentarios.map((item, index)=>(
                                      <Grid mt={1}>
                                        <Select className='font-size-select'
                                          isDisabled
                                          options={comentarios[index].comentarios} placeholder={`Seleccione ${comentarios[index].descripcion}`}
                                          value={getComentarios(index)}
                                          getOptionValue={(option) => `${option.id_comentario_adicional}`}
                                          getOptionLabel={(option) => `${option.descripcion}`}
                                        />
                                      </Grid>
                                    ))
                                  }
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} mb={2}>
                                <MDBox>
                                  <MDInput multiline rows={2} type="text" label="Agregue algún otro comentario que considere necesario" name="otro_comentario"
                                    value={formData.otro_comentario} fullWidth disabled />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h5" xs={12}fontSize={14} pb={0} >
                                    Condiciones comerciales
                                  </MDTypography>
                                  {
                                    condiciones.map((item, index)=>(
                                      <Grid mt={1}>
                                        <Select className='font-size-select'
                                          isDisabled
                                          options={condiciones[index].condiciones} placeholder={`Seleccione ${condiciones[index].descripcion}`}
                                          value={getCondiciones(index)} 
                                          getOptionValue={(option) => `${option.id_condicion_comercial}`}
                                          getOptionLabel={(option) => `${option.descripcion}`}
                                        />
                                      </Grid>
                                    ))
                                  }

                                </MDBox>
                              </Grid>
                              <Grid item xs={12}>
                                <MDBox>
                                  <MDInput multiline rows={2} type="text" label="Agregue alguna otra condición comercial que considere necesaria" name="otra_condicion"
                                    value={formData.otra_condicion} fullWidth disabled/>
                                </MDBox>
                              </Grid>
                            </Grid>
                        </Grid>
                    <Grid item xs={12}>
                      <Message status={status} />
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
        </MDBox>
	);
}

CotPreview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CotPreview;