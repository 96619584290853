import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';

function PDFGenerator({data}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    table:{
      width:"92%",
      height:"86%",
      display:"flex",
      borderColor:"#000000",
      borderWidth:1,
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },

    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },

    flexY:{
      flex: "1 1 auto",
    },

    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },

    flex5:{
      flex:4,
    },

    borderX:{
      borderRight:1,
      borderLeft:1,
    },

    borderY:{
      borderTop:1,
      borderBottom:1,
    },

    borderS:{
      borderLeft:1,
    },

    borderE:{
      borderRight:1,
    },

    borderT:{
      borderTop:1,
    },

    borderB:{
      borderBottom:1,
    },

    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 4,
      paddingBottom: 4,
    },

    textCenter:{
      textAlign:"center",
    },

    py10:{
      paddingTop:30,
      paddingBottom:30,
    },

    flex2:{
      flex:2,
    },

    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },

    img:{
      display:"block",
      position:"absolute",
      width:"10%",
      left:28,
      top: 5,
    },

    img2:{
      display:"block",
      position:"absolute",
      width:"20%",
      right:28,
      top: 22,
    },

    red:{
      color:"#ff0004"
    },

    textSmall:{
      fontSize: 8,
    },

    alend:{
      textAlign:"right"
    },

    pagina:{
      position:"fixed",
      bottom:15,
      left:0,
      paddingRight:28,
      textAlign:"right",
      fontSize:7,
    }

  });

  const Conceptos = (serv) => serv.map( concepto => 
    <View style={[styles.row, styles.borderB]} key={`concepto-${concepto.id_pivot}`}>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter]}>{concepto.clave}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter]}>{concepto.cantidad}</Text>
      </View>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter]}>{concepto.unidad}</Text>
      </View>
      <View style={[styles.col, styles.flex5]}>
        <Text style={[styles.center,styles.textCenter, styles.textSmall]}>{concepto.descripcion}</Text>
      </View>
    </View>);

  const Responsables = () => data.responsables ? data.responsables.map( responsable => 
    <View style={[styles.row, styles.borderB]} key={`resp-${responsable.id_pivot}`}>
      <View style={[styles.col, styles.borderE]}>
        <Text style={[styles.center,styles.textCenter, {marginBottom: 1, paddingBottom: 0}]}>{responsable.nombre}</Text>
        <Text style={[styles.center,styles.textCenter,styles.textSmall, {paddingTop: 0, marginTop: 0}]}>{responsable.puesto}</Text>
      </View>
      <View style={styles.col}>
        <Text style={[styles.center,styles.textCenter]}/>
      </View>
    </View>) : "";


  const size = 6; const arrayOfArrays = [];
  for (let i=0; i<data.servicios.length; i+=size) {
    arrayOfArrays.push(data.servicios.slice(i,i+size));
  }

  const Paginas = () => arrayOfArrays ? arrayOfArrays.map((servicios,i) => 
    <Page size="A4" wrap key={`page-${servicios.id_pivot}`}>
      
      <Image src={Logo} style={styles.img}/>

      <Image src={Logo2} style={styles.img2}/>
   
      <View style={styles.table}>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>No.:</Text>
          </View>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}>{data.id_oss}</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>No. Cotizacion:</Text>
          </View>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}>{data.num_cotizacion}</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Fecha:</Text>
          </View>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>{data.fecha_oss}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter, styles.red, styles.textSmall]}>{data.comentario}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Apartado A: Informacion del contratante</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Nombre comercial:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.cliente.nombre_comercial}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Razón Social:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.cliente.razon_social}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Persona de contacto:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.cliente.persona_contacto}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Dirección:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.direccion}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Estado:</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.flex2]}>
            <Text style={[styles.center]}>{data.estado}</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Municipio:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.municipio}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Telefono:</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.flex2]}>
            <Text style={[styles.center]}>{data.cliente.telefono}</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center]}>Correo:</Text>
          </View>
          <View style={[styles.col, styles.flex2]}>
            <Text style={[styles.center]}>{data.cliente.correo}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Apartado B: Conceptos</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Clave</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Cantidad</Text>
          </View>
          <View style={[styles.col, styles.borderE, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Unidad</Text>
          </View>
          <View style={[styles.col, styles.flex5, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Concepto</Text>
          </View>
        </View>
        {Conceptos(servicios)}
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}/>
          </View>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}/>
          </View>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}/>
          </View>
          <View style={[styles.col, styles.flex5]}>
            <Text style={[styles.center,styles.textCenter]}/>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Apartado C: Informacion adicional</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.flexY]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>{data.info_adicional}</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Apartado D: Responsable(s) de ejecución</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}>Nombre y Puesto</Text>
          </View>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Firma</Text>
          </View>
        </View>
        <Responsables />
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.head]}>
            <Text style={[styles.center,styles.textCenter]}>Apartado E: Firmas de autorización</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB, styles.head]}>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}>Elabora y emite</Text>
          </View>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>Acepta</Text>
          </View>
        </View>
        <View style={[styles.row, styles.borderB]}>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter, styles.py10]} />
          </View>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter, styles.py10]} />
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={[styles.col, styles.borderE]}>
            <Text style={[styles.center,styles.textCenter]}>{data.elabora}</Text>
            <Text style={[styles.center,styles.textCenter]}>{data.elabora_puesto}</Text>
          </View>
          <View style={styles.col}>
            <Text style={[styles.center,styles.textCenter]}>{data.acepta}</Text>
          </View>
        </View>
      </View>
      <Text style={[styles.pagina]}>Pagina {i+1} de {arrayOfArrays.length}</Text>
    </Page>) : "";

	return (
		<Document>
      <Paginas />
    </Document>
	);
}

PDFGenerator.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
};

export default PDFGenerator;