import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Select from 'react-select';
import { Divider, Icon } from "@mui/material";
import { parseCurrency } from "tools/utils";

function Servicios({serviciosArray, setServiciosArray, catalogoServicios, errors, setStatus, getSumPriceUnit}) {
  
  const [servicio, setServicio] = useState({
    cantidad: '',
    servicio: []
  });

  function randomString(longitud) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
  
    for (let i = 0; i < longitud; i+= 1) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indiceAleatorio);
    }
  
    return id;
  }

	const add = () => {
    if(servicio.cantidad === '' || servicio.servicio.length === 0){
      return;
    }
		setServiciosArray([
        ...serviciosArray,
          {... servicio, id_element: randomString(8)}
      ]);
		setServicio({
      cantidad: '',
      servicio: []
    });
    getSumPriceUnit();
	}

	const remove = (idElement, type) =>{

    if(type=== 'element'){      
      const newData = serviciosArray.filter(item=> item.id_element !== idElement);
      setServiciosArray(newData);
      return;
    }

    const newData = serviciosArray.filter(item=> item.id_pivot !== idElement);
    setServiciosArray(newData);
	}
  
	const handleChange = (event, index, name = "", value = "") => {
		  if(name === "servicio"){
        setServicio({
          ...servicio, 
            [name]:event,
          });
	    }else{
        setServicio({...servicio, [event.target.name]:event.target.value});
	    }

    if(errors!==null){
      const errName = name === "" ? `servicios.${index}.${event.target.name}` : `servicios.${index}.${name}`;
      if(errName in errors){
        const temp = errors;
        delete temp[errName];
        setStatus(status => ({...status, errors:temp}));
      }
    }

	}

  const verifyError = (name, index) =>{
    if(errors !== null){
      const errName = `servicios.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name, index) =>{
    if(errors !== null){
      const errName = `servicios.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return errors[errName][0];
      }
    }

    return "";
  }

  useEffect(()=>{
    setServiciosArray([]);
  },[])

return(
	<Fade in timeout={800}>
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography variant="h5" pb={1}>
            Conceptos
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDBox mb={2}>
            <MDInput type="number" label="Cantidad" name="cantidad"
              error={verifyError("cantidad")} errorMsg={getError("cantidad")}
              value={servicio.cantidad}
              onChange={handleChange} fullWidth
            />
          </MDBox>
        </Grid>
        <Grid item xs={8} mt="auto">
          <MDBox mb={2}>
            <small className="font-red">{getError("servicio")}</small>
            <Select className={`z-10 z-auto ${verifyError("servicio") ? "border-red" : ""}`}
              options={catalogoServicios} placeholder="Seleccione servicio"
              value={servicio.servicio} onChange={(e) => handleChange(e,"servicio","servicio")}
              getOptionValue={(option) => `${option.id_servicio}`}
              getOptionLabel={(option) => `${option.descripcion}`}
            />
          </MDBox>
        </Grid>
        <Grid item xs={2} mt="auto">
          <MDBox mb={2}>
            <MDButton variant="gradient" color="info" 
              onClick={()=>add()} fullWidth>
              Agregar
            </MDButton>
          </MDBox>
        </Grid>
        {
          serviciosArray ?
          serviciosArray.map(item=>(
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MDBox mb={2}>
                  <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center'}}>
                    {item.cantidad}
                  </p>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5}}>
                  {item.servicio.descripcion} 
                </p>
              </Grid>
              <Grid item xs={2}>
                <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                  {parseCurrency((parseFloat(item.servicio.p_unit) * parseInt(item.cantidad, 10)).toFixed(2))} 
                </p>
              </Grid>
              <Grid item xs={1} mt="auto">
                <MDBox mb={2}>
                  <MDButton variant="gradient" color="error" 
                    onClick={()=>remove(item.id_element ?? item.id_pivot, item.id_element ? 'element' : 'pivot')}>
                    <Icon>delete</Icon>
                  </MDButton>
                </MDBox>
              </Grid>
          </Grid>
          ))
          : null
        }
    </Grid>
  </Fade>);
}

Servicios.propTypes = {
  serviciosArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  catalogoServicios: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setServiciosArray: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  getSumPriceUnit: PropTypes.func.isRequired
};

export default Servicios;