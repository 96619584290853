/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import MDButton from "components/MDButton";
import Modal from 'react-modal';
import Select from 'react-select';
import { Alert, AlertTitle } from "@mui/material";
import Icon from "@mui/material/Icon";

import { Link } from "react-router-dom";

import { pdf, PDFDownloadLink } from '@react-pdf/renderer';

import PDFGeneratorCot from "tools/PDFGeneratorCot";
import Message from "tools/Message";
import OssForm from "./CotForm";
import CotPreview from "./CotPreview";

import {Get, Post, Put} from "../../tools/Helper";


function ListaCot() {
  const [showModal, setShowModal] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [typeDocument, setTypeDocument] = useState('cot');

  const [formData, setFormData] = useState({
    forma_pago: '',
    metodo_pago: '',
    uso_cfdi: ''
  });
  const [waysPayments, setWaysPayments] = useState([]);
  const [paymentsMethods, setPaymentsMethods] = useState([]);
  const [cFDIUses, setCFDIUses] = useState([]);
  const [generated, setGenerated] = useState(false);
  

  const handleChange = (e, name = "", value = "") =>{
    setFormData({
      ...formData, 
          [value]:e[name]
    });
}

  const getData = async e =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Get('cotizaciones');
    if(request.status === "success"){
      setData(request.data);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [editar, setEditar] = useState(true);

  const editarButton = (row) =>{
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const closeModal = () =>{
    setShowModal(false);
  }

  const closeModalPreview = () =>{
    setShowModalPreview(false)
  }

  const aprobarCancelarCotizacion = (row, type) =>{
    setTypeDocument(type);
    setShowModal(true);
    setSelectedData(row);
  }

  const previewCotizacion = (row) =>{
    setShowModalPreview(true);
    setSelectedData(row);
  }

  const downloadPDF = async(row) => { // prevent page reload
    const blob = await pdf(
        <PDFGeneratorCot data={row} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `cotizacion_${row.id_oss}.pdf`;
    link.click();
  }

  const generateCotToOSS = async() =>{
    setShowModal(false);
    setStatus({status: null, message: ""});
    setLoading(true);

    const statusOSS = {
      id_oss: selectedData.id_oss,
      status_oss: parseInt(selectedData.status_oss, 10) === 1 ? 0 : 1
    }
    const request = await Put(`cotizacion-oss/${selectedData.id_oss}`,statusOSS) ;

    if(request.status === "success"){
      setLoading(false);
      setStatus({status: 'success', message: "Se ha generado la OSS"});
      getData();
      return;
    }

    setStatus({status: 'error', message: "No se pudo crear la OSS"});
    setLoading(false);
  }

  const cancelCotizacion = async() =>{
    setShowModal(false);
    setStatus({status: null, message: ""});
    setLoading(true);

    const statusOSS = {
      id_oss: selectedData.id_oss,
      status_cotizacion: 2
    }
    const request = await Put(`cancelar-cotizacion/${selectedData.id_oss}`,statusOSS) ;

    if(request.status === "success"){
      setLoading(false);
      setStatus({status: 'success', message: "Se ha cancelado la cotización"});
      getData();
      return;
    }

    setStatus({status: 'error', message: "No se pudo cancelar la cotización"});
    setLoading(false);
  }

  const getWaysPayment = async () => {
    const route = `WaysPayment`;
    const send = await Get(route);
    if (send.code === 200) {
    setWaysPayments(send.data);
    }
  };

  const getPaymentsMethods = async () => {
      const route = `PaymentsMethods`;
      const send = await Get(route);
      if (send.code === 200) {
          setPaymentsMethods(send.data);
      }
  };

  const getCDFIUses = async () => {
      const route = `CFDIUses`;
      const send = await Get(route);
      if (send.code === 200) {
          setCFDIUses(send.data);
      }
  };

  const sendForm = async e =>{
    setStatus({status: 'warning', message: "Generando Factura..."});
    const tempData  = {
      ...formData,
      id_oss: selectedData.id_oss
    };
    const request = await Post('CreateCFDI',tempData)

    if (request.code === 201) {
      setStatus({
        message: "Se ha generado la factura satisfactoriamente",
        status: "success",
      });
      setGenerated(true);
      getData();
      return;
    }

    if (request.code === 401) {
      if (typeof request.message === 'object'){
        setStatus({
              message: request.message.message ?? "Ha ocurrido algo, intente de nuevo",
              status: "error",
            });
          return
      }
      setStatus({
        message: request.message,
        status: "error",
      });
      return;
  }

    setStatus({ 
      ...request, 
      status: 'error'
    });

  }


  useEffect(()=>{
    getData();
    getWaysPayment();
    getPaymentsMethods();
    getCDFIUses();
  },[]);


  const getListWaysPayments = waysPayments.find(option => option.key === formData.forma_pago);
  const getListPaymentsMethods = paymentsMethods.find(option => option.key === formData.metodo_pago);
  const getListCDFIUses = cFDIUses.find(option => option.key === formData.uso_cfdi);

  const buttonEdit = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;
  const buttonPdf = (row) => <MDButton variant="gradient" color="info" iconOnly onClick={()=>downloadPDF(row)}><Icon>picture_as_pdf</Icon></MDButton>;
  const buttonCancelarCot = (row, type) => <MDButton size="small" style={{ width: '100%', padding: 10 }} variant="gradient" color={parseInt(row.status_cotizacion,10) === 2 ? 'primary' : 'dark'} iconOnly onClick={()=>aprobarCancelarCotizacion(row, type)}><Icon>cancel</Icon>{parseInt(row.status_cotizacion,10) === 2 ? 'Cancelado' : 'Cancelar'}</MDButton>;
  const buttonGenerateOSS = (row, type) => <MDButton size="small" style={{ width: '100%', padding: 10 }} variant="gradient" color={parseInt(row.status_oss,10) === 1 ? 'success' : 'dark'} iconOnly onClick={()=>aprobarCancelarCotizacion(row, type)}><Icon>check_circle</Icon>{parseInt(row.status_oss,10) === 1 ? 'Aprobado' : 'Aprobar'}</MDButton>;
  const buttonFacturarCot = (row, type) => <MDButton size="small" style={{ width: '100%', padding: 10 }} variant="gradient" color={parseInt(row.facturado,10) === 1 ? 'info' : 'dark'} iconOnly onClick={()=>aprobarCancelarCotizacion(row, type)}><Icon>task</Icon>{parseInt(row.facturado,10) === 1 ? 'Facturado' : 'Facturar'}</MDButton>;
  const buttonPreviewCot = (row) => <MDButton variant="gradient" color='info' iconOnly onClick={()=>previewCotizacion(row)}><Icon>previewOutlinedIcon</Icon></MDButton>;


  const columns = [
      {
          name: 'N° Cotización',
          selector: row => row.num_cotizacion,
      },
      {
          name: 'Cliente',
          selector: row => row.cliente_nombre,
          wrap: true
      },
      {
          name: 'Total',
          selector: row => row.total,
      },
      {
        name: 'Fecha',
        selector: row => row.fecha_cotizacion,
      },
      {
        name: '',
        selector: row => buttonEdit(row),
        compact: true,
      },
      {
        name: '',
        selector: row => buttonPreviewCot(row),
        compact: true,
      },
      {
          name: '',
          selector: row => buttonPdf(row),
          compact: true,
      },
      {
        name: '',
        selector: row => parseInt(row.status_oss, 10) === 1 ? null : buttonCancelarCot(row, 'cot'),
        compact: true,
      },
      {
        name: '',
        selector: row => parseInt(row.status_cotizacion, 10) === 2 ? null : buttonGenerateOSS(row, 'oss'),
        compact: true,
      },
      {
        name: '',
        selector: row => parseInt(row.status_cotizacion, 10) === 2 ||  parseInt(row.status_oss, 10) === 0 ? null : buttonFacturarCot(row, 'fac'),
        compact: true,
      },
  ];

  const tableData = {
    columns,
    data,
  };

  const customStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 700,
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
        
       <MDBox pt={6} pb={3} display="block">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Fade in={show} timeout={800}>
            <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Lista de Cotizaciones
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <Link to="/cotizacion/captura-cotizacion">
                      <MDButton variant="gradient" color="light">
                        Agregar
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  <Message status={status} />
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox> :
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                      <DataTable
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'OSS por página:', 
                            rangeSeparatorText: 'de', 
                            noRowsPerPage: false, 
                            selectAllRowsItem: true, 
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    </DataTableExtensions>
                  }
                </MDBox>
              </MDBox>
            </Card>
            </Collapse>
            </Fade>
            {
              editar ? 
              <Fade in={!show} timeout={800}>
                <Collapse in={!show}  timeout={50}>
                  {!show ? <OssForm  data={selectedData} setShow={setShow} getLista={getData} show={show} /> : ""}
                </Collapse>
              </Fade> : null
            }
          </Grid>
          <Modal
            isOpen={showModal}
            style={customStyles}
          >
            <Grid container spacing={2} p={4}>
              <Grid item xs={6}>
                <h5>Cotización: {selectedData.num_cotizacion} </h5>
              </Grid>
              <Grid md={12}>
                <CotPreview  data={selectedData} setShow={setShow} getLista={getData} show={show} />
              </Grid>
              <Grid md={12}>
                <Message status={status} />
                {
                  ! selectedData.cliente?.uid  || selectedData.cliente?.uid === "" ?
                  <Alert severity="warning">
                    <AlertTitle>Alerta</AlertTitle>
                    <p className="pre-line">Este cliente no ha completado los datos de facturación.</p>
                 </Alert>
                 : null
                }
              </Grid>
              <Grid container md={12} spacing={2} p={5}>
                <Grid item md={4}>
                    <MDTypography variant="p" fontSize={12} >Forma de pago:</MDTypography>
                    <Select 
                        className= "z-101"
                        options={waysPayments} placeholder="Forma de pago"
                        value={getListWaysPayments || ''} onChange={(e) => handleChange(e,"key","forma_pago")}
                        getOptionValue={(option) => `${option.key}`}
                        getOptionLabel={(option) => `${option.name}`}
                        />
                </Grid>
                <Grid item md={4}>
                  <MDTypography variant="p" fontSize={12} >Método de pago:</MDTypography>
                  <Select 
                      className= "z-101"
                      options={paymentsMethods} placeholder="Método de pago"
                      value={getListPaymentsMethods || ''} onChange={(e) => handleChange(e,"key","metodo_pago")}
                      getOptionValue={(option) => `${option.key}`}
                      getOptionLabel={(option) => `${option.name}`}
                      />
                </Grid>
                <Grid item md={4}>
                  <MDTypography variant="p" fontSize={12} >Uso de CFDI:</MDTypography>
                  <Select 
                      className= "z-101"
                      options={cFDIUses} placeholder="Uso de CFDI"
                      value={getListCDFIUses || ''} onChange={(e) => handleChange(e,"key","uso_cfdi")}
                      getOptionValue={(option) => `${option.key}`}
                      getOptionLabel={(option) => `${option.name}`}
                      />
                </Grid>
            </Grid>
              <Grid container md={12} spacing={2}>
              {
                typeDocument === 'cot' ?
                <>
                  <Grid item md={6}>
                    <MDButton variant="gradient" color="dark" disabled={loading}
                    onClick={()=>closeModal()}
                    fullWidth>
                      CERRAR
                    </MDButton>
                  </Grid>
                  <Grid item container md={6} justifyContent="center" alignItems="center">
                      {
                        parseInt(selectedData.status_cotizacion, 10) === 2 ?
                        < MDTypography variant="p"><b>COTIZACIÓN CANCELADA</b></MDTypography>
                        :
                          <MDButton variant="gradient" color='primary' disabled={loading}
                          onClick = {()=>cancelCotizacion()}
                          fullWidth>
                            CANCELAR COTIZACIÓN
                          </MDButton>
                      }
                    </Grid>
                  </>
                  : null
                }
                {
                typeDocument === 'oss' ?
                <>
                  <Grid item md={6}>
                    <MDButton variant="gradient" color="dark" disabled={loading}
                    onClick={()=>closeModal()}
                    fullWidth>
                      CERRAR
                    </MDButton>
                  </Grid>
                    <Grid item md={6}>
                      <MDButton variant="gradient" color={parseInt(selectedData.status_oss, 10) === 1 ? 'primary' : 'info'} disabled={loading}
                      onClick = {()=>generateCotToOSS()}
                      fullWidth>
                        { parseInt(selectedData.status_oss, 10) === 1 ? 'REVERTIR OSS' : 'APROBAR COTIZACION Y GENERAR OSS'} 
                      </MDButton>
                    </Grid>
                  </>
                  : null
                }
                {
                typeDocument === 'fac' ?
                <>
                  <Grid item md={6}>
                    <MDButton variant="gradient" color="dark" disabled={loading}
                    onClick={()=>closeModal()}
                    fullWidth>
                      CERRAR
                    </MDButton>
                  </Grid>
                  {
                    selectedData.cliente?.uid ?
                      <Grid item container md={6} justifyContent="center" alignItems="center">
                        {
                          parseInt(selectedData.facturado, 10) === 1 || generated ?
                          < MDTypography color="success" variant="h3"><b>FACTURADO</b></MDTypography>
                          :
                            <MDButton variant="gradient" color='info' disabled={loading}
                            onClick = {()=>sendForm(false)}
                            fullWidth>
                              GENERAR FACTURA
                            </MDButton>
                        }
                      </Grid>
                    : null

                  }
                  </>
                  : null
                }
              </Grid>
            </Grid>
          </Modal>
          <Modal
            isOpen={showModalPreview}
            style={customStyles}
          >
            <Grid container spacing={2} p={4}>
              <Grid item xs={6}>
                <h5>Cotización: {selectedData.num_cotizacion} </h5>
              </Grid>
              <Grid md={12}>
                <CotPreview  data={selectedData} setShow={setShow} getLista={getData} show={show} />
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid item md={6}>
                  <MDButton variant="gradient" color="dark" disabled={loading}
                  onClick={()=>closeModalPreview()}
                  fullWidth>
                    CERRAR
                  </MDButton>
                </Grid>
                <Grid item md={6} />
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      </MDBox>
      {// selectedData.length !== 0 ? <PDFDownloadLink document={<PDFGenerator data={selectedData} />} fileName={`oss_${selectedData.id_oss}`} ref={downloadButton}/> : "bbbb"
      }
      <Footer />
    </DashboardLayout>
  );
}
export default ListaCot;
