/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from 'react-modal';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Icon from "@mui/material/Icon";
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';  
import { parseCurrency } from "tools/utils";
import PDFGeneratorCot from "tools/PDFGeneratorCot";

import {Get, Post, PostMulti} from "../../tools/Helper";

import Responsables from './data/Responsables';
import Servicios from './Servicios';

import Message from "../../tools/Message";
import CondicionesComerciales from "./CondicionesComerciales";
import ComentariosAdicionales from "./ComentariosAdicionales";

function Tables( { type }) {

  const scrollRef = useRef(React.createRef());
  const [inputKey, setInputKey] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [emailsCC, setEmailsCC] = useState('');

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;
  const nombreVendedor = JSON.parse(localStorage.getItem("user")).user.name;

  const emptyForm = {
    numero:'0',
    num_cotizacion:'',
    fecha_cotizacion:  datestring,
    fecha_oss: datestring,
    elabora:'',
    elabora_puesto:'',
    acepta:'',
    cliente: [],
    responsables: undefined,
    info_adicional:'',
    comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
    direccion: '',
    estado: '',
    municipio: '',
    moneda: 'MXN',
    nombre_vendedor: nombreVendedor,
    correo_vendedor: '',
    puesto_vendedor: '',
    texto_cotizacion: '',
    subtotal: 0,
    descuento: 0,
    iva: 0,
    total: 0,
    total_descuento: 0,
    status_cotizacion: 1,
    status_oss: 0,
    vigencia: 15,
    type,
    comentarios0:[],
    comentarios1:[],
    comentarios2:[],
    condiciones0:[],
    condiciones1:[],
    condiciones2:[],
    otra_condicion: '',
    otro_comentario: '',
  };

  const emptyServiciosArray = [{id_servicio:'', cantidad:'', descripcion:'', servicio:[]}];
  const emptyCondicionesArray = [{descripcion:'',  servicio:[]}];
  const emptyComentariosArray = [{descripcion:'',  servicio:[]}];

  const [formData, setFormData] = useState(emptyForm);

  const animatedComponents = makeAnimated();

  const [clientes, setClientes] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [condiciones, setCondiciones] = useState([
    {
      comentarios: ''
    }
  ]);
  const [comentarios, setComentarios] = useState([
    {
      comentarios: ''
    }
  ]);
  const [responsables, setResponsables] = useState([{nombre:'', puesto:''}]);

  const [usuariosArray, setUsuariosArray] = useState([]);
  const [serviciosArray, setServiciosArray] = useState(emptyServiciosArray);
  const [condicionesArray, setCondicionesArray] = useState(emptyCondicionesArray);
  const [comentariosArray, setComentariosArray] = useState(emptyComentariosArray);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [statusEmail, setStatusEmail] = useState(false);

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('catalogos');

    if(request.status === "success"){
      setClientes(request.clientes);
      setServicios(request.servicios);
      setResponsables(request.responsables);
      setCondiciones(request.condiciones_comerciales);
      setComentarios(request.comentarios_adicionales);
      setUsuariosArray(request.usuarios);
      setFormData({
        ...formData,
        num_cotizacion: `SUC1/${request.max_num_cot}`,
        numero: request.max_num_cot

      })
    }else{
      setStatus(request);
    }

    setLoading(false);
  }

  const getSumPriceUnit = (data, descuento = null) => {
    const sumPUnit = data.reduce((acumulador, elemento) => {
      const pUnit = parseFloat(elemento.servicio.p_unit);
      const importe = pUnit * parseInt(elemento.cantidad, 10)
      return acumulador + importe;
  }, 0);
  
    const totalDescuento = descuento ?? formData.descuento;
    setFormData({
      ...formData,
      descuento: totalDescuento,
      subtotal: (sumPUnit).toFixed(2),
      iva: (parseFloat((sumPUnit - totalDescuento).toFixed(2)) * 0.16).toFixed(2),
      total: (parseFloat((sumPUnit).toFixed(2)) * 1.16).toFixed(2),
      total_descuento: (parseFloat((sumPUnit - totalDescuento).toFixed(2)) * 1.16).toFixed(2),
    })
  };

  const setServArr = (data) =>{
    setServiciosArray(data);
    getSumPriceUnit(data);
  }

  useEffect(()=>{
    getData();
  },[]);

  useEffect(()=>{
    setFormData(emptyForm);
    setServiciosArray([]);
    setStatus({status: null, message: ""});
  },[type]);

  const handleChange = (e, name = "", value = "") =>{
    console.log(formData);
    if(name === ""){
      if(e.target.name === 'descuento'){
        getSumPriceUnit(serviciosArray, e.target.value);
        return;
      }
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      if(name === 'cliente'){
        const cliente = clientes.filter(item=> item.id_cliente === parseInt(e[value], 10))[0];
        setFormData({
          ...formData, 
            [value]:e[value], 
            [name]:e,
            acepta: cliente.persona_contacto,
          });
          return;
      }
      if(name === 'nombre_vendedor'){
        setFormData({
          ...formData, 
            nombre_vendedor:e.name, 
          });
          return;
      }
      setFormData({...formData, [value]:e[value], [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const handleChangeEmail = (e, name = "", value = "") =>{
    setEmailsCC(e.target.value);  
  }

  const submitOss = async(sendEmailsCC) =>{

    if(sendEmailsCC && emailsCC === '' ){
      setStatusEmail(true);
      return;
    }

    setIsOpen(false);
    
    const tempData = formData;
    tempData.servicios = serviciosArray;
    tempData.condiciones = condicionesArray;
    tempData.comentarios = comentariosArray;

    setStatus({status: 'warning', message: "Guardando..."});
    setLoading(true);

    const request = await Post('oss',tempData) ;

    if(request.status === "success"){
      setFormData(emptyForm);
      setInputKey(Math.random().toString(10));
      setServiciosArray(emptyServiciosArray);

      const doc = <PDFGeneratorCot data={request.data} />;
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const formData2 = new FormData();
      formData2.append("file", blob);
      formData2.append("id_oss", request.data.id_oss);

      if(sendEmailsCC){
        formData2.append("emails", emailsCC);
      }else{
        formData2.append("emails", '');
      }

      const request2 = await PostMulti('email',formData2) ;
      setEmailsCC('');
    }

    setStatus(request);

    if(scrollRef.current){
      scrollRef.current.scrollIntoView({ 
           behavior: "smooth", 
        });
    }

    setLoading(false);
  }

  const modalEmailsCC = () =>{
    setIsOpen(true);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const getVendedor = usuariosArray.find(option => option.name === formData.nombre_vendedor);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Capturar {type === 'cotizacion' ? 'Cotización' : 'OSS'}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Message status={status} />
                      </Grid>
                      {status.status === "success" ? 
                        <Grid item xs={4} mt="auto">
                          <PDFDownloadLink document={<PDFGeneratorCot data={status.data} />} fileName={`cotizacion_${status.data.num_cotizacion}`}>
                            <MDButton variant="gradient" color="success" fullWidth>
                              Descargar PDF <Icon>picture_as_pdf</Icon>
                            </MDButton>
                          </PDFDownloadLink>
                        </Grid>
                        :
                        ""}
                      {
                        type === 'cotizacion' ? // COTIZACION
                        <>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="No. Cotización" name="num_cotizacion" 
                                error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                                value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                            </MDBox>  
                          </Grid>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="date" label="Fecha" name="fecha_cotizacion"
                                error={verifyError("fecha_cotizacion")} errorMsg={getError("fecha_cotizacion")}
                                value={formData.fecha_cotizacion} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 9 }}>
                            <MDInput  type="number" label="Vigencia (días)" name="vigencia" 
                              error={verifyError("vigencia")} errorMsg={getError("vigencia")}
                              value={formData.vigencia} onChange={handleChange} />
                          </Grid>
                        </>
                        :
                        <>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="No. Cotización" name="num_cotizacion" 
                                error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                                value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="date" label="Fecha" name="fecha_oss"
                                error={verifyError("fecha_oss")} errorMsg={getError("fecha_oss")}
                                value={formData.fecha_oss} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                        </>
                      }

                      {
                        type !== 'cotizacion' ?
                        <>
                          <Grid item xs={12}>
                            <MDBox>
                              <MDInput type="text" label="Comentario" name="comentario"
                                error={verifyError("comentario")} errorMsg={getError("comentario")}
                                value={formData.comentario} onChange={handleChange}
                                multiline rows={5} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="Dirección" name="direccion" 
                                error={verifyError("direccion")} errorMsg={getError("direccion")}
                                value={formData.direccion} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="Estado" name="estado" 
                                error={verifyError("estado")} errorMsg={getError("estado")}
                                value={formData.estado} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={4} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="Municipio" name="municipio" 
                                error={verifyError("municipio")} errorMsg={getError("municipio")}
                                value={formData.municipio} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                        </>
                        : null
                      }
                    </Grid>

                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          <MDTypography variant="h5" pb={1}>
                            Cliente
                          </MDTypography>
                          <Select className={`z-10 ${verifyError("cliente") ? "border-red" : ""}`}
                            options={clientes} placeholder="Cliente..."
                            value={formData.cliente} onChange={(e) => handleChange(e,"cliente","id_cliente")}
                            getOptionValue={(option) => `${option.id_cliente}`}
                            getOptionLabel={(option) => `${option.nombre_comercial}`}
                          />
                          <small className="font-red">{getError("cliente")}</small>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Servicios serviciosArray={serviciosArray} setServiciosArray={setServArr} 
                      catalogoServicios={servicios} errors={"errors" in status?status.errors:null}
                      setStatus={setStatus} getSumPriceUnit = {getSumPriceUnit}/>

                    {
                      type === 'cotizacion' ? // COTIZACION
                      <>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                Subtotal
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="p" pb={1}>
                                {parseCurrency(formData.subtotal)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                Descuento
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDBox className="text-align-input">
                                <MDInput 
                                  className = "text-align-input"
                                  type="number" 
                                  label="" name="descuento" 
                                  error={verifyError("descuento")} 
                                  errorMsg={getError("descuento")}
                                  value={formData.descuento} 
                                  onChange={handleChange} />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="p" pb={1}>
                                I.V.A.
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="p" pb={1}>
                                {parseCurrency(formData.iva)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xs={6} md={8} />
                          <Grid container item spacing={2} xs={6} md={4}>
                            <Grid xs={1} md={2} />
                            <Grid item xs={5} md={4}>
                              <MDTypography variant="h5" pb={1}>
                                Total
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ textAlign:'right' }} >
                              <MDTypography variant="h5" pb={1}  >
                                {parseCurrency(formData.total_descuento)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid md={6}>
                            <MDBox mb={2}>
                            <MDTypography variant="h5" fontSize={14} pb={0} >
                              Vendedor
                            </MDTypography>
                              <Select className={`z-10 ${verifyError("nombre_vendedor") ? "border-red" : ""}`}
                                options={usuariosArray} placeholder="Vendedor..."
                                value={getVendedor} onChange={(e) => handleChange(e,"nombre_vendedor")}
                                getOptionValue={(option) => `${option.name}`}
                                getOptionLabel={(option) => `${option.name}`}
                              />
                              <small className="font-red">{getError("nombre_vendedor")}</small>
                              </MDBox>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h5" fontSize={14} pb={0} >
                                    Comentarios adicionales
                                  </MDTypography>
                                  {
                                    comentarios.map((item, index)=>(
                                      <Grid mt={1}>
                                        <Select className={`font-size-select ${verifyError("comentarios_adicionales") ? "border-red" : ""}`}
                                          options={comentarios[index].comentarios} placeholder={`Seleccione ${comentarios[index].descripcion}`}
                                          value={formData[`comentarios${index}`]} onChange={(e) => handleChange(e,`comentarios${index}`,"id_comentario_adicional")}
                                          getOptionValue={(option) => `${option.id_comentario_adicional}`}
                                          getOptionLabel={(option) => `${option.descripcion}`}
                                        />
                                      </Grid>
                                    ))
                                  }
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} mb={2}>
                                <MDBox>
                                  <MDInput multiline rows={2} type="text" label="Agregue algún otro comentario que considere necesario" name="otro_comentario"
                                    error={verifyError("otro_comentario")} errorMsg={getError("otro_comentario")}
                                    value={formData.otro_comentario} onChange={handleChange} fullWidth />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h5" xs={12}fontSize={14} pb={0} >
                                    Condiciones comerciales
                                  </MDTypography>
                                  {
                                    condiciones.map((item, index)=>(
                                      <Grid mt={1}>
                                        <Select className={`font-size-select ${verifyError("condiciones_comerciales") ? "border-red" : ""}`}
                                          options={condiciones[index].condiciones} placeholder={`Seleccione ${condiciones[index].descripcion}`}
                                          value={formData[`condiciones${index}`]} onChange={(e) => handleChange(e,`condiciones${index}`,"id_condicion_comercial")}
                                          getOptionValue={(option) => `${option.id_condicion_comercial}`}
                                          getOptionLabel={(option) => `${option.descripcion}`}
                                        />
                                      </Grid>
                                    ))
                                  }

                                </MDBox>
                              </Grid>
                              <Grid item xs={12}>
                                <MDBox>
                                  <MDInput multiline rows={2} type="text" label="Agregue alguna otra condición comercial que considere necesaria" name="otra_condicion"
                                    error={verifyError("otra_condicion")} errorMsg={getError("otra_condicion")}
                                    value={formData.otra_condicion} onChange={handleChange} fullWidth />
                                </MDBox>
                              </Grid>
                            </Grid>
                            {/* <ComentariosAdicionales comentariosArray={comentariosArray} setComentariosArray={setComentariosArray} 
                              catalogoComentarios={comentarios} errors={"errors" in status?status.errors:null}
                              setStatus={setStatus} />
                            <CondicionesComerciales condicionesArray={condicionesArray} setCondicionesArray={setCondicionesArray} 
                              catalogoCondiciones={condiciones} errors={"errors" in status?status.errors:null}
                              setStatus={setStatus} /> */}
                        </Grid>
                      </>
                      :
                      <>
                        <Grid item xs={12}>
                          <MDBox mb={2}>
                            <MDInput type="text" label="Información Adicional" name="info_adicional"
                              error={verifyError("info_adicional")} errorMsg={getError("info_adicional")}
                              value={formData.info_adicional} onChange={handleChange}
                              multiline rows={5} fullWidth />
                          </MDBox>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <MDBox mb={2}>
                            <MDTypography variant="h5" pb={1}>
                              Responsables
                            </MDTypography>
                              <Select className={`z-10 ${verifyError("responsables") ? "border-red" : ""}`}
                                closeMenuOnSelect
                                components={animatedComponents}
                                key={inputKey || ''}
                                isMulti
                                value={formData.responsables} onChange={(e) => handleChange(e, "responsables", "respArray")}
                                options={responsables}
                                getOptionValue={(option) => `${option.id_responsable}`}
                                getOptionLabel={(option) => `${option.nombre} - ${option.puesto}`}
                              />
                              <small className="font-red">{getError("responsables")}</small>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <MDBox>
                              <MDTypography variant="h5" pb={1}>
                                Firma de Autorizacion
                              </MDTypography>
                              <MDInput type="text" label="Nombre de quien elabora" name="elabora"
                                error={verifyError("elabora")} errorMsg={getError("elabora")}
                                value={formData.elabora} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={6} mt="auto">
                            <MDBox>
                              <MDInput type="text" label="Puesto" name="elabora_puesto"
                                error={verifyError("elabora_puesto")} errorMsg={getError("elabora_puesto")}
                                value={formData.elabora_puesto} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={6}>
                            <MDBox>
                              <MDInput type="text" label="Nombre de quien acepta" name="acepta"
                                error={verifyError("acepta")} errorMsg={getError("acepta")}
                                value={formData.acepta} onChange={handleChange} fullWidth />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </>
                    }


                    
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" 
                        onClick={() => modalEmailsCC()} fullWidth>
                        Generar y Enviar {type === 'cotizacion' ? 'Cotización' : 'OSS'}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
          isOpen={modalIsOpen}
          contentLabel="¿Seguro que desea eliminar el área?"
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <Grid item xs={12}>
              <h5>Envíar copia a correos:</h5>
            </Grid>
            <Grid xs={12}>
              <MDBox mb={2}>
                <MDInput type="text" label="Escriba los correos separados por coma." name="emails_cc"
                  error={verifyError("emails_cc")} errorMsg={getError("emails_cc")}
                  value={emailsCC} onChange={handleChangeEmail}
                  multiline fullWidth />
                  {
                    statusEmail ?
                      <p style={{color: 'red', fontSize: 12, padding: 5}}>Debe escribir al menos un correo válido</p>
                    : null
                  }
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>submitOss(false)}
                fullWidth>
                Omitir
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={() => submitOss(true)}
                fullWidth>
                Enviar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

Tables.propTypes = {
  type: PropTypes.bool.isRequired,
};

export default Tables;
