import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { Divider, TextField } from "@mui/material";
import Modal from 'react-modal';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import imageCompression from 'browser-image-compression';
import PropTypes from 'prop-types';

import Message from "../../tools/Message";

import {Get, Del, GetGuest, Post, Put} from "../../tools/Helper";

function FotosCurso({dataCursoProgramado, getFinalReport, setDataCursoProgramado}) {
  const [status, setStatus] = useState({status: null, message: ""});
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingContingencia, setLoadingContingencia] = useState(false);
  const [comentarios, setComentarios] = useState({});
  const [dataContingencia, setDataContingencia] = useState([]);
  const [dataContingencias, setDataContingencias] = useState([]);
  const [dataPhoto, setDataPhoto] = useState({
    foto: '',
    comentario: '',
    comentario_general: dataCursoProgramado.comentario_general
  });
  const [dataPhotos, setDataPhotos] = useState([]);

  const handleFile = async (event) => {
    setStatus({
      status: 'warning',
      message: "Comprimiendo imagen...",
    });
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;                
        setDataPhoto({
            ...dataPhoto, 
            foto: imgBase64data,
        });
        setStatus({
          status: null,
          message: "",
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
        setDataPhoto({...dataPhoto, [e.target.name]:e.target.value});
      }else{
        setDataPhoto({...dataPhoto, [value]:e[value], [name]:e});
      }
  }

  const handleChangeContingencia = (e, name = "", value = "") =>{
    if(name === ""){
        setDataContingencia({...dataContingencia, [e.target.name]:e.target.value});
      }else{
        setDataContingencia({...dataContingencia, [value]:e[value], [name]:e});
      }
  }

  const handleChangeCurso = (e) =>{
    setComentarios({
        ...comentarios,
          [e.target.name]:e.target.value
      });
  }

  const [editorState, setEditorState] = useState(null);
  const handleChangeCursoText = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState))
    setComentarios({
      ...comentarios,
      seccion6_recomenda_part: html
    });
    setEditorState(newEditorState);
  };

  const [editorState2, setEditorState2] = useState(null);
  const handleChangeCursoText2 = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
        setComentarios({
      ...comentarios,
      seccion11_comentarios: html
    });
    setEditorState2(newEditorState);
  };

  const getFotosCurso = async ()=>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const request = await Get(`foto-curso/${dataCursoProgramado.id_curso_programado}`);
    if(request.status === "success"){
        setDataPhotos(request.data);
        setLoading(false);
        return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const removePhoto = () =>{
    setDataPhoto({
        ...dataPhoto, 
        foto: null,
    });
  }

  const getContingencias = async ()=>{
    setStatus({status: null, message: ""});
    setLoadingContingencia(false);
    const request = await Get(`contingencia-ajuste/${dataCursoProgramado.id_curso_programado}`);
    if(request.status === "success"){
        setDataContingencias(request.data);
        setLoadingContingencia(true);
        return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const doRequest = async e =>{
    setStatus({status: null, message: ""});
    setLoading(true);
    const newData = {
        ...dataPhoto,
        id_curso_programado: dataCursoProgramado.id_curso_programado
    }
    const request =  await Post('foto-curso', newData) ;
    if(request.status === "success"){
        setDataPhoto({
            foto: '',
            comentario: '',
            comentario_general: dataCursoProgramado.comentario_general
        })
        getFotosCurso();
    }
    setStatus(request);
    setLoading(false);
  }

  const doRequestContingencia = async e =>{
    setStatus({status: null, message: ""});
    const newData = {
        ...dataContingencia,
        id_curso_programado: dataCursoProgramado.id_curso_programado
    }
    const request =  await Post('contingencia-ajuste', newData) ;
    if(request.status === "success"){
      setDataContingencia({
        contingencia: '',
        solucion: ''
      });
      getContingencias();
    }
    setStatus(request);
  }

  const EditPhoto = async (row) =>{
    setDataPhoto(row)
    setModalIsOpen(true);
  }

  const editFotoObservaciones = async()=>{
    setStatus({status: null, message: ""});
    setModalIsOpen(false);
    const request =  await Put(`foto-curso/${dataPhoto.id_fotografia_curso}`, dataPhoto);
    if(request.status === "success"){
      setDataPhoto({
        foto: '',
        comentario: '',
        comentario_general: dataCursoProgramado.comentario_general
      });
      getFotosCurso();
    }
    setStatus(request);
  }

  const DeletePhoto = async (IDPhoto) =>{
    setStatus({status: null, message: ""});
    const request = await Del(`foto-curso/${IDPhoto}`);
    if(request.status === "success"){
        setStatus({ status: "success", message: "Fotografía eliminada" });
        getFotosCurso();
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }
  

  const DeleteContingencia = async (IDContingencia) =>{
    setStatus({status: null, message: ""});
    const request = await Del(`contingencia-ajuste/${IDContingencia}`);
    if(request.status === "success"){
        setStatus({ status: "success", message: "Contingencia eliminada" });
        getContingencias();
      return;
    }
    setStatus({status:request.status, message:"Error."});
  }

  const updateCursoProgramado = async () => {
    setStatus({status: null, message: ""});
    const newData = {...dataCursoProgramado, ...comentarios};
    setDataCursoProgramado(newData);
    const request =  await Put(`programar-curso/${dataCursoProgramado.id_curso_programado}`, newData) ;
    getFinalReport();
    setStatus(request);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const setHtmlToEditor = () => {
    if(dataCursoProgramado.seccion6_recomenda_part){
      const blocksFromHTML = convertFromHTML(dataCursoProgramado.seccion6_recomenda_part);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }

    if(dataCursoProgramado.seccion11_comentarios){
      const blocksFromHTML2 = convertFromHTML(dataCursoProgramado.seccion11_comentarios);
      const contentState2 = ContentState.createFromBlockArray(
        blocksFromHTML2.contentBlocks,
        blocksFromHTML2.entityMap
      );
      const newEditorState2 = EditorState.createWithContent(contentState2);
      setEditorState2(newEditorState2);
    }
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setDataPhoto({
      foto: '',
      comentario: '',
      comentario_general: dataCursoProgramado.comentario_general
    });
  }

  useEffect(()=>{
    getFotosCurso();
    getContingencias();
    setHtmlToEditor();
  },[])

  const buttonEditar = (row) => <MDButton onClick={()=>EditPhoto(row)} variant="gradient" color="warning" iconOnly style={{ borderRadius: 10 }}><Icon style={{ color: 'white',  fontSize: 20 }}>edit</Icon></MDButton>;
  const buttonEliminar = (row) => <MDButton onClick={()=>DeletePhoto(row.id_fotografia_curso)} variant="gradient" color="error" iconOnly style={{ borderRadius: 10 }}><Icon style={{ color: 'white',  fontSize: 20 }}>delete</Icon></MDButton>;
  const getPhoto = (row) => <img alt="Fotografía" src={row.foto} width="100%" />
  const columns = [
    {
        name: 'Observación',
        selector: row => row.comentario,
        wrap: true,
        grow:2
    },
    {
      name: 'Referencia',
      selector: row => row.referencia,
      wrap: true,
      grow:2
    },
    {
        name: 'Foto',
        selector: row => getPhoto(row),
        wrap: true,
        grow:2
    },
    {
      name: '',
      selector: row => buttonEditar(row),
  },
    {
        name: '',
        selector: row => buttonEliminar(row),
    },
  ];

  const data = dataPhotos;
  const tableData = {
    columns,
    data
  };

  const customStyleMap = {
    'CUSTOM_FONT_SIZE': {
      fontSize: '14px',
    },
  };

  const editorStyle = {
    fontSize: '14px',
  };

  const Contingencias = () => dataContingencias ? dataContingencias.map(item =>
    <>
      <Grid item p={2} xs={5} mt="auto" >
        <p className="textTable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{ item.contingencia } </p>
      </Grid>
      <Grid item p={2} xs={5} mt="auto">
        <p className="textTable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{ item.solucion }</p>
      </Grid>
      <Grid item p={2} xs={2} >
        <MDButton onClick={()=>DeleteContingencia(item.id_contingencia)} variant="gradient" color="error" iconOnly style={{ borderRadius: 10 }}>
            <Icon style={{ color: 'white',  fontSize: 20 }}>delete</Icon>
        </MDButton>
      </Grid>
    </>
  ) : '';

  return (
  <MDBox>
    <Grid container spacing={2}>
      <Grid container spacing={2} xs={12} md={6} p={3} mt="auto">
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 2: COMENTARIOS ACERCA DEL PROCESO DE APRENDIZAJE Y DEL GRUPO</MDTypography>
        <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={3} label="" name="seccion2_comentarios"
            error={verifyError("seccion2_comentarios")} errorMsg={getError("seccion2_comentarios")}
            value={comentarios.seccion2_comentarios} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.seccion2_comentarios} />
        </Grid>
        <Divider />
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 3: NIVEL DE CUMPLIMIENTO DE LOS OBJETIVOS/RESULTADOS DE APRENDIZAJE Y DE LAS EXPECTATIVAS DEL CURSO
        </MDTypography>
        <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={3} label="" name="seccion3_cumplimiento"
            error={verifyError("seccion3_cumplimiento")} errorMsg={getError("seccion3_cumplimiento")}
            value={comentarios.seccion3_cumplimiento} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.seccion3_cumplimiento} />
        </Grid>
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 4: PLAN DE SEGUIMIENTO A LOS PARTICIPANTES EN LA APLICACIÓN DE LO APRENDIDO </MDTypography>
        <Grid item xs={12} mt="auto">
          <MDTypography variant="caption" pt={2}>¿Qué se desea conseguir?</MDTypography>
          <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={2} label="" name="que_desea"
            error={verifyError("que_desea")} errorMsg={getError("que_desea")}
            value={comentarios.que_desea} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.que_desea} />
          </Grid>
        </Grid>
        <Grid item xs={12} mt="auto">
          <MDTypography variant="caption" pt={2}>¿Cómo se desea conseguir?</MDTypography>
          <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={2} label="" name="como_desea"
            error={verifyError("como_desea")} errorMsg={getError("como_desea")}
            value={comentarios.como_desea} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.como_desea} />
          </Grid>
        </Grid>
        <Grid item xs={12} mt="auto">
          <MDTypography variant="caption" pt={2}>¿Cuándo se desea conseguir?</MDTypography>
          <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={2} label="" name="cuando_desea"
            error={verifyError("cuando_desea")} errorMsg={getError("cuando_desea")}
            value={comentarios.cuando_desea} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.cuando_desea} />
          </Grid>
        </Grid>
        <Grid item xs={12} mt="auto">
          <MDTypography variant="caption" pt={2}>¿Dónde se desea conseguir?</MDTypography>
          <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={2} label="" name="donde_desea"
            error={verifyError("donde_desea")} errorMsg={getError("donde_desea")}
            value={comentarios.donde_desea} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.donde_desea} />
          </Grid>
        </Grid>
        <Grid item xs={12} mt="auto">
          <MDTypography variant="caption" pt={2}>¿Quién debe ayudar a conseguirlo?</MDTypography>
          <Grid item xs={12} mt="auto">
            <TextField multiline type="textarea" rows={2} label="" name="quien_debe"
            error={verifyError("quien_debe")} errorMsg={getError("quien_debe")}
            value={comentarios.quien_debe} onChange={handleChangeCurso} 
            fullWidth defaultValue={dataCursoProgramado.quien_debe} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} xs={12} md={6} p={3} mt="auto">
        {/* <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 6: RECOMENDACIONES VERTIDAS POR LOS PARTICIPANTES EN LA ENCUESTA DE SATISFACCIÓN PARA LA MEJORA DEL CURSO</MDTypography>
        <Grid item xs={12} mt="auto">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleChangeCursoText}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            customStyleMap={customStyleMap}
            editorStyle={editorStyle}
          />
        </Grid> */}
        <Grid container item xs={12} spacing={2} mt="auto">
          <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 5: CONTINGENCIAS/AJUSTES AL PLAN DE SESIÓN QUE SE PRESENTARON Y SU RESOLUCIÓN</MDTypography>
          <Grid item p={2} xs={6} mt="auto" >
            <p className="textTable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>Contingencia/ajuste</p>
          </Grid>
          <Grid item p={2} xs={6} mt="auto">
            <p className="textTable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>Solución</p>
          </Grid>
          {
            ! loadingContingencia ?
                <MDBox display="block" fullWidth textAlign="center" p={5}>
                <CircularProgress size="2rem" /> 
                </MDBox>
            : <Contingencias />
          }
          <Grid item xs={12} mt="auto">
              <MDInput type="text" label="Contingencia/ajuste" name="contingencia"
              error={verifyError("contingencia")} errorMsg={getError("contingencia")}
              value={dataContingencia.contingencia} onChange={handleChangeContingencia} fullWidth />
          </Grid>
          <Grid item xs={12} mt="auto">
              <MDInput type="text" label="Solucion" name="solucion"
              error={verifyError("solucion")} errorMsg={getError("solucion")}
              value={dataContingencia.solucion} onChange={handleChangeContingencia} fullWidth />
          </Grid>
          <Grid item xs={12} md={12}>
              <MDButton onClick={()=>doRequestContingencia()} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="success" mx={4} fullWidth>
                  Agregar contingencia
              </MDButton>
          </Grid>       
        </Grid>
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 10: AVANCES LOGRADOS CON RELACIÓN A LOS RESULTADOS DE APRENDIZAJE PLANEADOS</MDTypography>
        <Grid item xs={12} mt="auto">
          <TextField multiline type="textarea" rows={3} label="" name="seccion10_avances"
          error={verifyError("seccion10_avances")} errorMsg={getError("seccion10_avances")}
          value={comentarios.seccion10_avances} onChange={handleChangeCurso} 
          fullWidth defaultValue={dataCursoProgramado.seccion10_avances} />
        </Grid>
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 11: COMENTARIOS ADICIONALES</MDTypography>
        <Grid item xs={12} mt="auto">
          <Editor
            editorState={editorState2}
            onEditorStateChange={handleChangeCursoText2}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            customStyleMap={customStyleMap}
            editorStyle={editorStyle}
          />
        </Grid>
        <MDTypography variant="h6" pt={2} pl={3}>SECCIÓN 12: RECOMENDACIONES</MDTypography>
        <Grid item xs={12} mt="auto">
          <TextField multiline type="textarea" rows={3} label="" name="seccion12_recomend"
          error={verifyError("seccion12_recomend")} errorMsg={getError("seccion12_recomend")}
          value={comentarios.seccion12_recomend} onChange={handleChangeCurso} 
          fullWidth defaultValue={dataCursoProgramado.seccion12_recomend} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid item xs={12} md={12}>
          <Grid container xs={12} md={12} mt="auto" style={{ float: 'left', justifyContent: 'start' }}>
              {
                  dataPhoto.foto ?
                  <Grid container spacing={2} xs={12} mt="auto">
                    <Grid item xs={12} mt="auto">
                      <img
                      alt="Fotografía"
                      src={dataPhoto.foto}
                      width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} mt="auto">
                        <MDInput type="text" label="Observación" name="comentario"
                        error={verifyError("comentario")} errorMsg={getError("comentario")}
                        value={dataPhoto.comentario} onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} mt="auto">
                        <MDInput type="text" label="Referencia" name="referencia"
                        error={verifyError("referencia")} errorMsg={getError("referencia")}
                        value={dataPhoto.referencia} onChange={handleChange} fullWidth />
                    </Grid>
                  </Grid>
                  : null
              }
          </Grid>
          <Message status={status} />
          {
              dataPhoto.foto ?
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                          <MDButton onClick={()=>removePhoto()} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="error" mx={4} fullWidth>
                              eliminar
                          </MDButton>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <MDButton onClick={()=>doRequest()} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="success" mx={4} fullWidth>
                              Guardar
                          </MDButton>
                      </Grid>
                  </Grid>
              : null
          }
          {
            !dataPhoto.foto ?
                <div style={{ width: '100%', display: 'flex', marginLeft: 80, marginTop: 80 }} id="src-foto" className='file-select'>
                    <input type='file' accept="image/*" 
                        onChange={event => handleFile(event)}
                    />
                </div>
            : null
          }
        </Grid>
        <Divider />
        <MDBox>
        {loading ? 
            <MDBox display="block" fullWidth textAlign="center" p={5}>
            <CircularProgress size="10rem" /> 
            <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
            </MDBox>
          : 
            <DataTableExtensions
                {...tableData}
                export={false}
                print={false}
                filterPlaceholder="Buscar..."
                filter={false}
            >
                <DataTable
                columns={columns}
                data={dataPhotos}
                pagination
                paginationComponentOptions={{
                    rowsPerPageText: 'Fotos por página:', 
                    rangeSeparatorText: 'de', 
                    noRowsPerPage: false, 
                    selectAllRowsItem: true, 
                    selectAllRowsItemText: 'Todos'
                }}
                noDataComponent={<MDBox py={5}>No se encontraron fotos.</MDBox>}
                />
            </DataTableExtensions>
        }
        </MDBox>
      </Grid>
    </Grid>
    <Message status={status} />
    <Grid item mt={3} xs={12} textAlign="right" >
        <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="info" fullWidth onClick={()=>updateCursoProgramado()}>
            Guardar
        </MDButton>
    </Grid>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal Observaciones"
      >
      <Grid container spacing={6} p={10}>
        <Grid container spacing={6} mt={5} p={4} item xs={12}>
          <Grid px={5} xs={6}>
            <Grid item xs={12} mb={2} mt="auto">
                <TextField multiline type="textarea" rows={4} label="Observación" name="comentario"
                error={verifyError("comentario")} errorMsg={getError("comentario")}
                value={dataPhoto.comentario} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} mt="auto">
                <TextField multiline type="textarea" rows={3} label="Referencia" name="referencia"
                error={verifyError("referencia")} errorMsg={getError("referencia")}
                value={dataPhoto.referencia} onChange={handleChange} fullWidth />
            </Grid>
          </Grid>
          <Grid xs={6}>
            {
              dataPhoto.foto ?
              <Grid container spacing={2}>
                <img
                  alt="Fotografía"
                  src={dataPhoto.foto}
                  width="100%" 
                  style={{ marginTop: 10 }}
                  />
                      <Grid item xs={12} md={6}>
                          <MDButton onClick={()=>removePhoto()} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="error" mx={4} fullWidth>
                              eliminar
                          </MDButton>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <MDButton onClick={()=>doRequest()} style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="success" mx={4} fullWidth>
                              Guardar
                          </MDButton>
                      </Grid>
                  </Grid>
              : null
          }
          {
            !dataPhoto.foto ?
                <div style={{ width: '100%', display: 'flex', marginLeft: 80, marginTop: 80 }} id="src-foto" className='file-select'>
                    <input type='file' accept="image/*" 
                        onChange={event => handleFile(event)}
                    />
                </div>
            : null
          }
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <MDButton variant="gradient" style={{ color: 'white' }} color="error" fullWidth onClick={()=>closeModal()}>
              Cerrar
          </MDButton>
        </Grid>
        <Grid item xs={6} textAlign="right" >
          <MDButton variant="gradient" style={{ color: 'white' }} color="success" fullWidth onClick={()=>editFotoObservaciones()}>
            Actualizar
          </MDButton>
        </Grid>
      </Grid>
    </Modal>
  </MDBox>
  );
}

FotosCurso.propTypes = {
    dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    getFinalReport: PropTypes.func.isRequired,
    setDataCursoProgramado: PropTypes.func.isRequired,
  };
  
export default FotosCurso;
