
export function getDateLarge(date) {
    const newDate = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('es-MX', options)
}

export function getHoursLarge(datetime) {
    const newDatetime = new Date(datetime);
	return newDatetime.getHours('es-MX')
}

export function parseCurrency(value) {
    const formatCurrency = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      });
      
      return formatCurrency.format(value);
}
