/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";
import makeAnimated from 'react-select/animated';

import {Post} from "../../tools/Helper";

import Message from "../../tools/Message";

function ChangePassword() {

  const scrollRef = useRef(React.createRef());

  const emptyForm = {
    actual_password:'',
    new_password: ''
  };

  const [formData, setFormData] = useState(emptyForm);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

 
  const handleChange = (e, name = "", value = "") =>{
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      setFormData({...formData, [value]:e[value], [name]:e});
    }

    if("errors" in status){
      const verifyName = name === "" ? e.target.name : name;
      if(verifyName in status.errors){
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const submiPass = async() =>{
    const tempData = formData;
    console.log(tempData);

    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Post('change-password',tempData) ;

    if(request.status === "success"){
      setFormData(emptyForm);
    }

    setStatus(request);

    if(scrollRef.current){
      scrollRef.current.scrollIntoView({ 
           behavior: "smooth", 
        });
    }

    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} ref={scrollRef}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Cambiar mi contraseña
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Message status={status} />
                      </Grid>
                      <Grid item xs={6} mt="auto">
                        <MDBox>
                          <MDInput type="password" label="Contraseña actual" name="actual_password" 
                            error={verifyError("actual_password")} errorMsg={getError("actual_password")}
                            value={formData.actual_password} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6} mt="auto">
                        <MDBox>
                          <MDInput type="password" label="Contraseña nueva" name="new_password"
                            error={verifyError("new_password")} errorMsg={getError("new_password")}
                            value={formData.new_password} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider />
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" 
                        onClick={() => submiPass()} fullWidth>
                        Actualizar contraseña
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

export default ChangePassword;
