/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseItemArray,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";


function SidenavArray({ icon, name, array, act}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [active, setActive] = useState(act);
  useEffect(()=>{
    setActive(act);
  },[act]);

  const renderRoutes = array.map(({ typeArr, nameArr, iconArr, titleArr, keyArr, hrefArr, routeArr }) => 
  	<Link key={keyArr} to={routeArr}>
      <SidenavCollapse name={nameArr} icon={iconArr} active={keyArr === collapseName} />
    </Link>
  );

  return (

    <ListItem component="li">
      <MDBox
        sx={(theme) => active ?
          collapseItemArray(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          }) : collapseItem (theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <List fullWidth>
        	<ListItem component="li" onClick={()=>setActive(!active)}>
        	<ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
        	</ListItem>
      		<Fade in={active} timeout={800}>
		    	<Collapse in={active} timeout={50}>
		    		{ renderRoutes }
		    	</Collapse>
	    	</Fade>
	   </List>
      </MDBox>

    </ListItem>

  );
}

// Typechecking props for the SidenavCollapse
SidenavArray.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  array: PropTypes.arrayOf(PropTypes.object).isRequired,
  act: PropTypes.bool.isRequired,
};

export default SidenavArray;
