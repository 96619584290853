/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';


import Icon from "@mui/material/Icon";


import Modal from 'react-modal';
import CursoForm from "./CursoForm";
import {Get, Del} from "../../tools/Helper";
import Message from "../../tools/Message";

function Curso() {

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;

  const emptyForm = {
    nombre:'',
    id_area: '',
    duracion_horas: '',
    fecha_registro: datestring,
    area: '',
    evaluacion_curso: {
      id_evaluacion: 0
    }
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [selectedDelete, setSelectedDelete] = useState(null);

  const editarButton = (row) =>{
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const [data, setData] = useState([]);
  const [dataEvaluaciones, setDataEvaluaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`cursos`);

    if(request.status === "success"){
      setData(request.data.cursos);
      setDataEvaluaciones(request.data.evaluaciones)
      setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(idPregunta) {
    setIsOpen(true);
    setSelectedDelete(idPregunta);
  }

  const deleteCurso = async () =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`cursos/${selectedDelete}`);

    if(request.status === "success"){
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const deleteButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>openModal(row.id_pregunta)}><Icon>deleteForever</Icon></MDButton>;

  const columns = [
    {
        name: 'Id',
        selector: row => row.id_curso,
        grow:1,
        sortable: true,
    },
    {
        name: 'Nombre del curso',
        selector: row => row.nombre,
        wrap:true,
        grow:3,
    },
    {
        name: 'Área',
        selector: row => row.area,
        grow:2
    },
    {
        name: 'Duración en horas',
        selector: row => row.duracion_horas,
        grow:1
    },
    {
        name: 'Fecha de registro STPS',
        selector: row => row.fecha_registro,
        grow:1
    },
    {
        name: '',
        selector: row => editButton(row),
        grow:0,
        compact: true,
    },
    {
        name: '',
        selector: row => deleteButton(row),
        grow:0,
        compact: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                        Lista de cursos
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton variant="gradient" color="light" 
                      onClick={()=>crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar Curso
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <MDBox>
                    <DataTableExtensions
                      {...tableData}
                      export={false}
                      print={false}
                      filterPlaceholder="Buscar..."
                    >
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: 'Cursos por página:', 
                        rangeSeparatorText: 'de', 
                        noRowsPerPage: false, 
                        selectAllRowsItem: true, 
                        selectAllRowsItemText: 'Todos'
                      }}
                      noDataComponent={<MDBox py={5}>No se encontraron cursos.</MDBox>}
                    />
                    </DataTableExtensions>
                  </MDBox>
                  }
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <CursoForm data={selectedData} editar={editar} dataEvaluaciones = {dataEvaluaciones} setShow={setShow} 
              getLista={getData} />
          </Collapse>
        </Fade>
      </MDBox>
        <Modal
          isOpen={modalIsOpen}
          contentLabel="¿Seguro que desea eliminar el curso?"
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <h2>¿Seguro que desea eliminar el curso?</h2>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={()=>closeModal()} fullWidth>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>deleteCurso()} fullWidth>
                Eliminar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Curso;