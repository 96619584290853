
import React, { useState, useEffect } from "react";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider, TextField } from "@mui/material";

import PropTypes from 'prop-types';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Post } from "tools/Helper";

import Message from "../../../tools/Message";

function TemplateEvaluacion({
    dataEvaluation, 
    questions, 
    setAnswerQuestions, 
    setDataCursante, 
    dataCursante, 
    isResult, 
    typeEvaluation, 
    tokenUserExternal,
    getEvaluacion,
    setDataComentario,
    loading
  }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState({status: null, message: ""});
    const [statusEmail, setStatusEmail] = useState(false);
    const [cursoCerrado, setCursoCerrado] = useState(false);
    const [email, setEmail] = useState('');
    const [numOportunidades, setNumOportunidades] = useState([]);
    const [numComentario, setNumComentario] = useState(0);

    const handleChange = (e, name = "", value = "") =>{
        if(name === ""){
            setEmail({...email, [e.target.name]:e.target.value});
          }else{
            setEmail({...email, [value]:e[value], [name]:e});
          }
    
        if("errors" in status){
          const verifyName = name === "" ? e.target.name : name;
          if(verifyName in status.errors){
            const temp = status;
            delete temp.errors[verifyName];
            setStatus(temp);
          }
        }
    }

    const answerOption = (answer, IDPreguntaEvaluacion) =>{
        const newQuestions = questions;
        const indexQuestion = newQuestions.findIndex((item)=> parseInt(item.id_pregunta_evaluacion,10) === parseInt(IDPreguntaEvaluacion,10));
        const questionOriginal = newQuestions.find((item)=> parseInt(item.id_pregunta_evaluacion, 10) === parseInt(IDPreguntaEvaluacion,10));
        const answerOriginal = questionOriginal.optionsValue.find(item => item.option === answer);
        newQuestions[indexQuestion].answer_response = answer;
        newQuestions[indexQuestion].id_respuesta_evaluacion = answerOriginal.id_respuesta_evaluacion;

        setAnswerQuestions(newQuestions);
    }

    const emailParticipante = searchParams.get("email");
    let attempt = searchParams.get("attempt");

    const verificaCorreo =async()=>{
        setDataCursante({});
        setStatus({status: null, message: ""});
        setStatusEmail(false);

        const newData = {
          email: ! emailParticipante ? email.email :  emailParticipante,
          tipo: typeEvaluation,
          token: tokenUserExternal
        }
        
        const request =  await Post(`valida-email-evaluacion`, newData);
        if(request.status === 'error'){
            setStatus({status: 'warning', message: "Escriba un correo válido"})
            return;
        }
        if(request.status === 'completed'){
          setCursoCerrado(true);
          setStatus({status: 'error', message: "Este curso está cerrado"})
          return;
      }
        if(request.status === 'empty'){
            setStatus({status: 'error', message: "Este correo no se encuentra registrado, escriba uno válido"})
            return;
        }
        
        setDataCursante(request.data);
        setStatusEmail(true);

        if(! emailParticipante)
        {
          getEvaluacion(email.email, attempt);
        }else{
          getEvaluacion(emailParticipante, attempt);
        }

        console.log(attempt)
        const numOportuni = [];
        for (let i = 1; i<= request.data.num_oportunidades; i+= 1){
          numOportuni.push(i);
        }
        setNumOportunidades(numOportuni);
    }

    const verifyError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return true;
          }
        }
    
        return false;
      }
    
    const getError = (name) =>{
        if("errors" in status){
            if(name in status.errors){
            return status.errors[name][0];
            }
        }

    return "";
    }

    const navigate = useNavigate();
    const navigateResult = (num) =>{
      if(num === 'new'){
        navigate(`/evaluacion-curso?tk=${tokenUserExternal}&type=${typeEvaluation}`);        
        return;
      }
      attempt = searchParams.get("attempt");
      navigate(`/evaluacion-curso?tk=${tokenUserExternal}&type=${typeEvaluation}&attempt=${num}`);
    }

    const getClassSelect = (optionsValue, index2)=>{
      let color = '#FFFFF';
      if(typeEvaluation === 'servicio'){
        return color;
      }
      
      if(isResult && parseInt(optionsValue[index2].correct,10) === 1 && ( parseInt(optionsValue[index2].correct,10) === parseInt(optionsValue[index2].status_respuesta,10))){
        color = '#d0ffcb';
      }else if(parseInt(optionsValue[index2].status_respuesta,10) === 1){
        color = '#ffbbbb';
      }
       return color;
    }

    const getEvaluationByEmail = () =>{
      setEmail({
        email: emailParticipante
      })
    }

    useEffect(()=>{
      getEvaluationByEmail();
    },[])


    useEffect(()=>{
      verificaCorreo();
    },[attempt])

   return (
    <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
      {
        cursoCerrado ? <Message item xs={12} md={8} status={status} />
        :
        <>
          <Grid container xs={12} md={12} mt={5}>
            <Grid item xs={12} md={8} mt={5}>
              {
                typeEvaluation === 'final' && dataCursante.num_oportunidades ?
                <p style={{ fontSize: 14 }} >Intentos: 
                  {
                  numOportunidades.map(item=>(
                      <MDButton onClick={()=>navigateResult(item)} style={{ borderRadius: 10, color: 'white', margin: 1 }} variant="gradient" color={item === parseInt(attempt,10) ? 'info' : 'light'} iconOnly >{ item }</MDButton>
                    ))
                  }
                  {
                    numOportunidades.length === parseInt(attempt,10) && !isResult ?
                      <MDTypography variant="caption" style={{color: 'red'}} >No guardado</MDTypography>   
                    : null
                  }
                </p>
                  : null
              }
              <MDTypography variant="h4" style={{fontWeight: 'bold'}}  xs={12} md={12}>{dataEvaluation.titulo}</MDTypography>
              <p style={{ fontSize: 12 }} >{dataEvaluation.descripcion}</p>
            </Grid>
            {
              isResult && typeEvaluation !== 'servicio' ?
              <Grid item xs={12} md={4} mt={5} pl={2}>
                <p style={{ fontSize: 10 }} >Resultado</p>
                <MDTypography variant="h2" style={{fontWeight: 'bold'}}  xs={12} md={12}>{dataEvaluation.correctas}/{dataEvaluation.total}</MDTypography>
              </Grid>
              :
              null
            }
          </Grid>
          <Grid item xs={12} md={8} mt={5}>
              <MDTypography variant="p" pt={3} pr={3}>Correo electrónico <span style={{ color: 'red' }}>*</span></MDTypography>
              <MDInput type="email" label="" name="email"
              error={verifyError("email")} errorMsg={getError("email")}
              value={email.email} onChange={handleChange} onBlur= {()=>verificaCorreo()} fullWidth />
          </Grid>
        { statusEmail ? <MDTypography style={{ color: 'green', fontSize: 14 }} variant="caption" pt={3} pr={3}>Correo válido <Icon style={{ paddingTop: 1 }} fontSize="small">check_circle</Icon></MDTypography> : '' }
          <Message item xs={12} md={8} status={status} />
          { loading ? 
                <Grid xs={12} md={12} container spacing={1} p={5} >
                    <Grid item xs={12} md={12} mt={5} style={{ zIndex: 9 }}>
                        <MDBox item display="block" fullWidth textAlign="center" p={5}>
                            <CircularProgress size="10rem" /> 
                            <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
                : 
            <>
              {questions.map((question, index) => (
              <div style={{ paddingTop: 30 }} key={question.id_pregunta_evaluacion}>
                  <MDTypography  key={question.id_pregunta_evaluacion}  style={{ fontSize: 16, fontWeight: 'bold' }} variant="p" pt={3} pr={3}>{index + 1 }.- {question.question}</MDTypography>
                  <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue={isResult ? question.correctAnswer : null}
                      >
                      {
                          question.options.map((option, index2) => (
                              <FormControlLabel style={{ padding: 0, background: getClassSelect(question.optionsValue, index2)}} onClick={()=>answerOption(option, question.id_pregunta_evaluacion)} key={option} value={option} control={<Radio />} label={<MDTypography style={{ fontSize: 14, fontWeight: 'normal' }} variant="p" pt={3} pr={3}>{question.optionsValue[index2].option}</MDTypography>}/>
                          ))
                      }
                  </RadioGroup>
              </div>
              ))}
            </>
          }
          {
            typeEvaluation === 'servicio' ?
              <Grid item xs={12} mt={3}>
                <MDTypography   style={{ fontSize: 16, fontWeight: 'bold' }} variant="p" pt={3} pr={3}>Comentarios adicionales</MDTypography>
                <MDTypography variant="caption" pt={3} pr={3}>{numComentario}/150</MDTypography>
                <TextField multiline type="textarea" rows={2} label="" name="cuando_desea"
                error={verifyError("cuando_desea")} errorMsg={getError("cuando_desea")}
                onChange={(e)=>{
                  setNumComentario((e.target.value).length)
                  setDataComentario(e.target.value);
                }}
                inputProps={{ maxLength: 150 }} fullWidth/>
              </Grid>
            : null
          }
        </>
      }
    </Grid>
  );
}

TemplateEvaluacion.propTypes = {
    dataEvaluation: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    questions:  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    setAnswerQuestions: PropTypes.func.isRequired,
    setDataCursante: PropTypes.func.isRequired,
    dataCursante:  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    isResult: PropTypes.bool.isRequired,
    typeEvaluation: PropTypes.string.isRequired,
    tokenUserExternal: PropTypes.string.isRequired,
    getEvaluacion: PropTypes.func.isRequired,
    setDataComentario: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

export default TemplateEvaluacion;
