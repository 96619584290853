import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Select from 'react-select';
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {Get, Del, GetGuest} from "../../tools/Helper";

import Message from "../../tools/Message";
import TablaCursantes from "./TablaCursantes";
import TablaCursantesCurso from "./TablaCursantesCurso";


function AltaCursantes() {
  const [status, setStatus] = useState({status: null, message: ""});
  const [dataCursantes, setDataCursantes] = useState([]);
  const [dataCursantesCurso, setDataCursantesCurso] = useState([]);
  const [dataCurso, setDataCurso] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const tokenCurso = searchParams.get("tk");
  const getCursantesCursos = async (data)=>{    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get(`cursantes-curso/${tokenCurso}`);
    
    if(request.status === "success"){
        const newDataCusantes = [];
        let cursanteCurso = {};
        request.data.cursantes.forEach(item=>{
            const cursante = request.data.cursantes_cursos.filter(item2 => parseInt(item2.id_cursante, 10) === parseInt(item.id_cursante, 10))
            console.log(cursante)
            if(cursante[0]){
                cursanteCurso = {
                    nombre_completo: item.nombre_completo,
                    id_cursante: item.id_cursante,
                    id_cursante_programado: cursante[0].id_cursante_programado,
                    agregado: 1
                }
            }else{
                cursanteCurso = {
                    nombre_completo: item.nombre_completo,
                    id_cursante: item.id_cursante,
                    id_cursante_programado: 0,
                    agregado: 0
                }
            }
            newDataCusantes.push(cursanteCurso);
        })
        setDataCursantes(newDataCusantes);
        setDataCurso(request.data.curso);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  useEffect(()=>{
    getCursantesCursos();
  },[]);

  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                    <MDBox mx={2} mt={-3} py={3} px={2}
                    variant="gradient" bgColor="info"
                    borderRadius="lg" coloredShadow="info"
                    >
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                    Alta de participante al curso: { dataCurso.curso ? dataCurso.curso.nombre : null }
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                        <Message status={status} />
                        <Grid pt={3} pl={3} spacing={1}>
                            <MDTypography variant="h4" pt={3}>Participantes agregados: { dataCursantes ? dataCursantes.filter(item => item.agregado === 1).length : null } /{ dataCurso ? dataCurso.num_participantes : null } </MDTypography>
                            <p>Personal relacionado con la empresa. </p>
                        </Grid>
                        <MDBox>
                            <Grid container p={3} spacing={1}>
                                <Grid item p={6} xs={12} md={12} >
                                    <Grid>
                                    {loading ? 
                                    <MDBox display="block" fullWidth textAlign="center" p={5}>
                                        <CircularProgress size="10rem" /> 
                                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                                    </MDBox>
                                    : 
                                    <MDBox>
                                        <TablaCursantes dataCurso = {dataCurso} getCursantesCursos = {getCursantesCursos} dataCursantes = {dataCursantes} dataCursantesCurso = {dataCursantesCurso} />
                                    </MDBox>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    </DashboardLayout>
  );
}

export default AltaCursantes;
