import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

function EstatusFactura({dataChart}){
  const data = {
    labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    datasets: [
      {
        label: 'Pendientes de Emisión',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: dataChart.pendientesEmision,
      },
      {
        label: 'Pendientes de Pago',
        backgroundColor: 'rgba(255,206,86,0.4)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
        data: dataChart.pendientesPago,
      },
      {
        label: 'Canceladas',
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        data: dataChart.canceladas,
      },
    ],
  };

  const options = {
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div className='card-dashboard'>
      <p>Estado de Facturas</p>
      <Bar data={data} options={options} />
    </div>
  );
}

EstatusFactura.propTypes = {
  dataChart: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default EstatusFactura;
