/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate, Link  } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import CircularProgress from "@mui/material/CircularProgress";
import brandWhite from "assets/images/logo-login.png";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import Message from "../../../tools/Message";

import {Post} from "../../../tools/Helper";

function Basic() {

  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [data, setData] = useState({email:'',password:''});
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  let isSubscribed = true;

  const handleChange = (e) =>{
    setData({...data, [e.target.name]:e.target.value});
  }

  const handleLogin = async() =>{

    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Post('auth/login',data,navigate);

    setLoading(false);
    if(request.status === "success"){
      await localStorage.setItem('user', JSON.stringify(request.data));

      navigate("/login-red");
      
    }

    setStatus(request);
  
  }

  useEffect(()=>{
     isSubscribed = true;
    return () => {
        isSubscribed = false;
    }
  });



  return (
    <BasicLayout image={bgImage}>
      
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          position="relative"
        >
        <MDBox component="img" src={brandWhite} alt="Brand" textAlign="center" position="absolute" top="-9.5rem" right={0} left={0} mx="auto" width="12rem"/>
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Bienvenido
          </MDTypography>
        </MDBox>
        {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox> : <MDBox pt={4} pb={3} px={3}>
                  <Message status={status} />
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="email" label="Correo" fullWidth 
                      name="email" value={data.email} onChange={handleChange} />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type={showPassword ? 'text' : 'password'} label="Contraseña" fullWidth 
                      name="password" value={data.password} onChange={handleChange} />
                      <div className="eye-password">
                        <Icon onClick={()=>setShowPassword(!showPassword)} fontSize="small">{showPassword ? 'visibility' : 'visibility_off'}</Icon>
                      </div>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                    <Link
                      key="recover-password"
                      to="/recover-password?tk=o23f4t77n93qjfq"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Olvidé mi contraseña
                      </MDTypography>
                    </Link>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      
                      <MDButton variant="gradient" color="info" fullWidth
                        onClick={handleLogin}>
                        Iniciar Sesión
                      </MDButton>
              
                    </MDBox>
                  </MDBox>
                </MDBox>}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
