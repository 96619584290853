import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font  } from '@react-pdf/renderer';
import Moment from 'react-moment';
import 'moment/locale/es';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';
import Fondo from './diploma_fondo.jpg';
import QR from './frame.png';
import Montserrat from './montserrat-medium.ttf';
import MontserratBold from './montserrat-bold.ttf';


function PDFGenerator({data, qr}) {

  Font.register({
  family: 'Montserrat',
    fonts: [
      {
        src: Montserrat,
      },
      {
        src: MontserratBold,
        fontWeight: 'bold',
      },
    ],
  });

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        fontSize: '15px',
        padding: 0,
        lineHeight: 1.5,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },
    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },
    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 3,
      paddingBottom: 3,
      textAlign:"center",
    },
    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },
    img:{
      display:"block",
      position:"absolute",
      width:"10%",
      left:28,
      top: 5,
    },
    red:{
      color:"#AB272C"
    },
    title:{
    	fontSize: '24px',
    	color: '#01304E'
    },
    subtitle:{
    	fontSize: '19.5px',
    	color:"#AB272C"
    },
    textSmall:{
      fontSize: '12px',
    },
    textSmall1:{
      fontSize: '14px',
    },
    textSmall2:{
      fontSize: '8px',
    },
    bgImage: {
        height: '100%',
        width: '100%',
        position: "absolute",
        left: '0px',
        right: '0px',
    },
    inline:{
    	display: 'inline',
    },
    firstPadding:{
    	paddingTop: 118
    },
    table:{
    	display: 'flex',
    },
    firma:{
    	height: 110,
    	maxWidth: '100%'
    },
    qr:{
    	height: 100,
    	width: 100,
    	marginRight: 'auto',
    	marginLeft: 'auto',
    },
    px2:{
    	paddingRight: 45,
    	paddingLeft: 45,
    },
    px1:{
    	paddingLeft: 18,
      marginRight: 18,
      marginLeft: 18,
    	paddingRight: 18,
      lineHeight: 1.1,
    },
    textCenter:{
    	textAlign: 'center',
    },
    mtAuto:{
    	marginTop: 'auto',
    	marginBottom: 0
    },
    pt1:{
    	paddingTop: 5
    },
    pt2:{
    	paddingTop: 20
    },
    pl:{
    	paddingLeft: 15
    },
    pr:{
    	paddingRight: 15
    },
    bold:{
      fontWeight: 'bold',
    }
  });
    
	return (
      	<Page size="LETTER" orientation="landscape" wrap style={styles.page} scale="1.5">
      		<Image style={styles.bgImage}  src={Fondo} alt="images" />
      		<View style={styles.table}>
      		<View style={[styles.row, styles.firstPadding]}>
		        <View style={styles.col}>
		        	<Text style={[styles.center,styles.title, styles.bold]}>Certificado de terminación de curso</Text>
					<Text style={[styles.center]}>Se otorga este certificado orgullosamente a: </Text>
					<Text style={[styles.center,styles.subtitle, styles.bold]}>{data.nombre_cursante}</Text>
					<Text style={[styles.center]}>Por haber concluido satisfactoriamente el curso:</Text>
					<Text style={[styles.center,styles.subtitle, styles.bold]}>{data.nombre_curso}</Text>
					<Text style={[styles.center,styles.textCenter]}>
						Con una duración de {data.duracion_curso} horas e impartido en {data.lugar}
          </Text>
				</View>
				
			</View>
			<View style={[styles.row, styles.px2, styles.pt2, styles.textCenter]}>
					<View style={[styles.col, styles.pr]}>
						<Image style={styles.firma}  src={data.instructor.firma} alt="images" />
						<Text style={styles.textSmall}>{data.instructor.nombre_completo}</Text>
            {
              data.instructor.registro_1 ?
                <Text style={styles.textSmall2}>{data.instructor.registro_1}</Text>
              : null
            }
            {
              data.instructor.registro_2 ?
                <Text style={styles.textSmall2}>{data.instructor.registro_2}</Text>
              : null
            }
						<Text style={styles.textSmall}>{data.instructor?.puesto}</Text>
					</View>
					<View style={[styles.col, styles.textCenter]}>
						<Text style={styles.textSmall1}>Se extiende el certificado el día {data.fecha_termino}</Text>
						<View style={[styles.row, styles.pt1]}>
							<View style={[styles.col, styles.textCenter]}>
                <Text>No. de Certificado:</Text>
								<Text style={styles.red}>{data.folio_unico_registro}</Text>
								<Image style={styles.qr} src={qr} alt="images" />
								<Text style={[styles.textSmall2, styles.px1]}>Escanea el código QR para autenticar</Text>
							</View>
						</View>
					</View>
					<View style={[styles.col, styles.pl, styles.textCenter]}>
						<Image style={styles.firma}  src={data.administrador.firma} alt="images" />
						<Text style={styles.textSmall}>{data.administrador.nombre_completo}</Text>
            {
              data.administrador.registro_1 ?
                <Text style={styles.textSmall2}>{data.administrador.registro_1}</Text>
              : null
            }
            {
              data.administrador.registro_2 ?
                <Text style={styles.textSmall2}>{data.administrador.registro_2}</Text>
              : null
            }
						<Text style={styles.textSmall}>{data.administrador?.puesto}</Text>
					</View>
				</View>
			</View>
	    </Page>
	);
}

PDFGenerator.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
  qr: PropTypes.node.isRequired
};

export default PDFGenerator;