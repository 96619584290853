import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import { Divider } from '@mui/material';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';
import { getDateLarge, getHoursLarge } from './utils';

function PDFGeneratorActaConstitutiva({dataReporte}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.4,
        flexDirection: 'column',
        display:"flex",
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    table:{
      width:"98%",
      height:"86%",
      display:"flex",
      borderColor:"#000000",
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },

    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },

    flexY:{
      flex: "1 1 auto",
    },

    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },

    flex5:{
      flex:4,
    },

    borderX:{
      borderRight:1,
      borderLeft:1,
    },

    borderY:{
      borderTop:1,
      borderBottom:1,
    },

    borderS:{
      borderLeft:1,
    },

    borderE:{
      borderRight:1,
    },

    borderT:{
      borderTop:1,
    },

    borderB:{
      borderBottom:1,
    },

    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 4,
      paddingBottom: 4,
    },

    textCenter:{
      textAlign:"center",
    },

    textJustify:{
      textAlign:"justify",
    },

    py10:{
      paddingTop:30,
      paddingBottom:30,
    },

    topSection:{
      marginTop: 30
    },

    marginTopMedium: {
      marginTop: 9.5
    },

    flex15:{
      flex:1.5,
    },

    flex2:{
      flex:2,
    },

    head:{
      fontWeight:"heavy",
      backgroundColor:"#dedede",
      fontFamily: 'Helvetica-Bold'
    },

    title:{
      fontWeight:"heavy",
      fontFamily: 'Helvetica-Bold'
    },

    img:{
      display:"block",
      position:"absolute",
      width:"15%",
      left:50,
      top: 25,
    },

    img2:{
      display:"block",
      position:"absolute",
      height:"50",
      right:50,
      top: 25,
    },

    red:{
      color:"#ff0004"
    },

    textSmall:{
      fontSize: 8,
    },

    alend:{
      textAlign:"right"
    },

    pagina:{
      position:"fixed",
      bottom:15,
      left:0,
      paddingRight:28,
      textAlign:"right",
      fontSize:7,
    }

  });

  const getListaCursantes = (cursantes)=>{
    let listCursantes = '';
    cursantes.forEach(element => {
      listCursantes += `${element.cursante.nombre_completo}, `
    });
    return listCursantes;
  }

    const BrigadistasCuadros = (brigadistas) =>
    brigadistas.map(item =>
        <>
            <View style={[styles.borderX, styles.borderT]}>
                <View style={[styles.row, styles.borderB, styles.head]}>
                <View style={styles.col}>
                    <Text style={[styles.center,styles.textCenter]}>{item.cursante.id_puesto_brigada ? item.cursante.puesto_brigada.descripcion : ''}</Text>
                </View>
                </View>
                <View style={[styles.row, styles.borderB]}>
                <View style={[styles.col, styles.borderE, styles.head]}>
                    <Text style={[styles.center,styles.textCenter]}>Nombre</Text>
                </View>
                <View style={[styles.col, styles.head]}>
                    <Text style={[styles.center,styles.textCenter]}>Firma</Text>
                </View>
                </View>
                <View style={[styles.row, styles.borderB]}>
                <View style={[styles.col, styles.borderE, { padding: 3}]}>
                    <Text style={[styles.center,styles.textCenter,  { fontSize: 8 }]}>{item.cursante.nombre_completo} </Text>
                </View>
                <View style={[styles.col, { padding: 3}]}>
                    <Text style={[styles.center,styles.textCenter]}/>
                </View>
                </View>
            </View>
            <Divider style={[styles.marginTopMedium]} />
        </>
    );

    const BrigadistasJefes = (brigadistas) => 
    brigadistas.map(item =>
        <View>
            <View style={[styles.row]}>
                <View style={[styles.col, styles.borderB, {margin: 1}]}>
                <Text style={[styles.center,styles.textCenter,  { fontSize: 8 }]}> {item.cursante.nombre_completo} </Text>
                </View>
                <View style={[styles.col, styles.borderB, {margin: 1}]}>
                <Text style={[styles.center,styles.textCenter]}/>
                </View>
            </View>
            <View style={[styles.row]}>
                <View style={[styles.col]}>
                <Text style={[styles.center,styles.textCenter, {marginTop: 1}]}>{item.cursante.id_puesto_brigada ? item.cursante.puesto_brigada.descripcion : ''}</Text>
                </View>
                <View style={[styles.col]}>
                <Text style={[styles.center,styles.textCenter, {marginTop: 1}]}>Suplente</Text>
                </View>
            </View>
        </View>
    );

    const BrigadistasTodos = (brigadistas) => 
    brigadistas.map(item =>
        <View style={[styles.row]}>
            <View style={styles.col}>
                <Text style={[styles.marginTopMedium]}>{item.cursante.id_puesto_brigada ? item.cursante.puesto_brigada.descripcion : ''} </Text>
                <Text style={[styles.title]}>C. {item.cursante.nombre_completo}</Text>
            </View>
        </View>
    );

    const size = 7; const arrayOfArrays = [];
    for (let i=0; i<dataReporte.cursante_programado.length; i+=size) {
        arrayOfArrays.push(dataReporte.cursante_programado.slice(i,i+size));
    }
    const numBrigadistas = arrayOfArrays.length;

    const size2 = 6; const arrayOfArraysJefes = [];
    for (let i=0; i<dataReporte.cursante_programado.length; i+=size2) {
        arrayOfArraysJefes.push(dataReporte.cursante_programado.slice(i,i+size2));
    }
    const numBrigadistasJefes = arrayOfArraysJefes.length;

	return (
    <Document>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.title]}>
                {dataReporte.cliente.razon_social}
              </Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.center, styles.textCenter, styles.title]}>
                Acta Constitutiva de la Unidad Interna de Protección Civil
              </Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.textJustify]}>
              En la Ciudad de {dataReporte.cliente.municipio_inmueble}, 
              siendo el {getDateLarge(dataReporte.fecha_inicio)}, se reúnen en el inmueble ubicado 
              en {dataReporte.cliente.calle_num_inmueble}, {dataReporte.cliente.colonia_inmueble}, {dataReporte.cliente.municipio_inmueble}, {dataReporte.cliente.estado_inmueble}, donde se encuentra las 
              instalaciones de {dataReporte.cliente.razon_social}, 
              los CC.: {getListaCursantes(dataReporte.cursante_programado)}
              con objeto de constituir formalmente la Unidad Interna de Protección Civil de conformidad con las siguientes manifestaciones: {'\n'}{'\n'}

                Con fundamento en la Ley General de Protección Civil,  la Ley de Protección Civil del Estado de Tabasco, 
                en el Decreto por el que se aprueban las Bases para el Establecimiento del Sistema Nacional de Protección Civil.- Diario Oficial de la Federación 
                del 6 de Mayo de 1986.- Manual de Organización y Operación del Sistema Nacional de Protección Civil .- Diario Oficial de la Federación del 23 de octubre del 2006.- 
                Decreto por el que se crea el Consejo Nacional de Protección Civil.- Diario Oficial de la Federación del 11 de mayo de 1990.- Programa de Protección Civil 2006 – 2012; la {dataReporte.cliente.razon_social}, constituye la Unidad Interna de Protección Civil del Inmueble. {'\n'}{'\n'}

                La Unidad Interna de Protección Civil del inmueble es el órgano operativo para prevenir y enfrentar emergencias que 
                pudieran presentarse en el inmueble de referencia y tiene: {'\n'}{'\n'}

                La responsabilidad de desarrollar y dirigir las acciones de Protección Civil, así como de elaborar, implementar, coordinar 
                y operar el Programa Interno y sus correspondientes subprogramas de prevención, auxilio y restablecimiento con el objeto de 
                prevenir o mitigar los daños que puedan ocasionar los desastres o siniestros en su personal, o público, patrimonio y/o 
                entorno dentro de sus instalaciones. {'\n'}{'\n'}

                La Unidad Interna de Protección Civil queda integrada por las siguientes personas: {'\n'}{'\n'}

            </Text>
            </View>
          </View>
          {BrigadistasTodos(arrayOfArrays[0])}
        </View>
      </Page>

      {
        numBrigadistas > 0 && numBrigadistas < 3 ?

      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
          {
            arrayOfArrays.slice(1,).map(item=>(
                BrigadistasTodos(item)
            ))
          }
          <Divider style={[styles.marginTopMedium]} />
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.textJustify]}>
                De conformidad con los preceptos legales aplicables, el desempeño de estas comisiones no significa nuevo nombramiento 
                o cambio de las condiciones de la relación laboral con la dependencia, por considerarse una obligación para el trabajador, 
                sin representar remuneración alguna. {'\n'}{'\n'}

                La Unidad Interna de Protección Civil tendrá las atribuciones y funciones que se dictan en el contexto del presente documento. {'\n'}{'\n'}

                Leída la presente acta firman los que en ella intervienen de conformidad para los fines y efectos legales que haya lugar, 
                en la ciudad de {dataReporte.cliente.municipio_inmueble}, {dataReporte.cliente.estado_inmueble}, 
                siendo las {getHoursLarge(dataReporte.created_at)} horas del {getDateLarge(dataReporte.fecha_inicio)}.

              </Text>
            </View>
          </View>
          <Divider style={[styles.marginTopMedium]} />
          {BrigadistasJefes(arrayOfArraysJefes[0])}
        </View>
      </Page>

      : null
    }

      {
        numBrigadistas > 2 && numBrigadistas < 5 ?
        <>
          <Page size="LETTER" wrap style={styles.page} scale="1.5">
          <Image src={Logo} style={styles.img}/>
          <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          <View style={styles.table}>
          {            
              arrayOfArrays.slice(1,3).map(item=>(
                  BrigadistasTodos(item)
              ))
          }
          </View>
        </Page>
      
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
          {
            arrayOfArrays.slice(3,).map(item=>(
                BrigadistasTodos(item)
            ))
          }
          <Divider style={[styles.marginTopMedium]} />
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.textJustify]}>
                De conformidad con los preceptos legales aplicables, el desempeño de estas comisiones no significa nuevo nombramiento 
                o cambio de las condiciones de la relación laboral con la dependencia, por considerarse una obligación para el trabajador, 
                sin representar remuneración alguna. {'\n'}{'\n'}

                La Unidad Interna de Protección Civil tendrá las atribuciones y funciones que se dictan en el contexto del presente documento. {'\n'}{'\n'}

                Leída la presente acta firman los que en ella intervienen de conformidad para los fines y efectos legales que haya lugar, 
                en la ciudad de {dataReporte.cliente.municipio_inmueble}, {dataReporte.cliente.estado_inmueble}, 
                siendo las {getHoursLarge(dataReporte.created_at)} horas del {getDateLarge(dataReporte.fecha_inicio)}.

              </Text>
            </View>
          </View>
          <Divider style={[styles.marginTopMedium]} />
          {BrigadistasJefes(arrayOfArraysJefes[0])}
        </View>
      </Page>
    </>
      : null
    }

{
        numBrigadistas > 4 && numBrigadistas < 7 ?
        <>
        <Page size="LETTER" wrap style={styles.page} scale="1.5">
          <Image src={Logo} style={styles.img}/>
          <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          <View style={styles.table}>
          {            
              arrayOfArrays.slice(1,3).map(item=>(
                  BrigadistasTodos(item)
              ))
          }
          </View>
        </Page>

        <Page size="LETTER" wrap style={styles.page} scale="1.5">
          <Image src={Logo} style={styles.img}/>
          <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
          <View style={styles.table}>
          {            
              arrayOfArrays.slice(3,5).map(item=>(
                  BrigadistasTodos(item)
              ))
          }
          </View>
        </Page>
        
      
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
          {
            arrayOfArrays.slice(5,).map(item=>(
                BrigadistasTodos(item)
            ))
          }
          <Divider style={[styles.marginTopMedium]} />
          <View style={[styles.row]}>
            <View style={styles.col}>
              <Text style={[styles.textJustify]}>
                De conformidad con los preceptos legales aplicables, el desempeño de estas comisiones no significa nuevo nombramiento 
                o cambio de las condiciones de la relación laboral con la dependencia, por considerarse una obligación para el trabajador, 
                sin representar remuneración alguna. {'\n'}{'\n'}

                La Unidad Interna de Protección Civil tendrá las atribuciones y funciones que se dictan en el contexto del presente documento. {'\n'}{'\n'}

                Leída la presente acta firman los que en ella intervienen de conformidad para los fines y efectos legales que haya lugar, 
                en la ciudad de {dataReporte.cliente.municipio_inmueble}, {dataReporte.cliente.estado_inmueble}, 
                siendo las {getHoursLarge(dataReporte.created_at)} horas del {getDateLarge(dataReporte.fecha_inicio)}.

              </Text>
            </View>
          </View>
          <Divider style={[styles.marginTopMedium]} />
          {BrigadistasJefes(arrayOfArraysJefes[0])}
        </View>
      </Page>
    </>
      : null
    }

{
  numBrigadistasJefes > 0 && numBrigadistasJefes < 3 ?
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(1,).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        <Divider style={[styles.marginTopMedium]} />
        {BrigadistasCuadros(arrayOfArraysJefes[0])}
        </View>
      </Page>: null
}

{
  numBrigadistasJefes > 2 && numBrigadistasJefes < 5 ?
  <>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(1,3).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
  
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(3,).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        <Divider style={[styles.marginTopMedium]} />
        {BrigadistasCuadros(arrayOfArraysJefes[0])}
        </View>
      </Page>
      </>
      : null
}

{
  numBrigadistasJefes > 4 && numBrigadistasJefes < 7 ?
  <>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(1,3).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
    
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(3,5).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
    
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
    
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(5,).map(item=>(
                BrigadistasJefes(item)
            ))
          }
        <Divider style={[styles.marginTopMedium]} />
        {BrigadistasCuadros(arrayOfArraysJefes[0])}
        </View>
      </Page>

      </>
      : null
}

{
    numBrigadistasJefes > 1 && numBrigadistasJefes < 3 ?
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            numBrigadistasJefes > 1 ?
            arrayOfArraysJefes.slice(1,).map(item=>(
                BrigadistasCuadros(item)
            ))
            : null
          }
        </View>
      </Page>: null
}

{
    numBrigadistasJefes > 2 && numBrigadistasJefes < 5 ?
    <>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(1,3).map(item=>(
                BrigadistasCuadros(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(3,).map(item=>(
                BrigadistasCuadros(item)
            ))
          }
        </View>
      </Page>
      </>
      : null
}

{
    numBrigadistasJefes > 4 && numBrigadistasJefes < 7 ?
    <>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(1,3).map(item=>(
                BrigadistasCuadros(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(3,5).map(item=>(
                BrigadistasCuadros(item)
            ))
          }
        </View>
      </Page>
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image src={Logo} style={styles.img}/>
        <Image src={dataReporte.cliente.logo_cliente} style={styles.img2}/>
        <View style={styles.table}>
            {
            arrayOfArraysJefes.slice(5,).map(item=>(
                BrigadistasCuadros(item)
            ))
          }
        </View>
      </Page>
      </>
      : null
}


    </Document>
	);
}

PDFGeneratorActaConstitutiva.propTypes = {
  dataReporte: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
};

export default PDFGeneratorActaConstitutiva;