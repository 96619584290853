/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";

import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import Modal from 'react-modal';
import UserForm from "./UserForm";
import {Get,Del} from "../../tools/Helper";

function Usuario() {

  const emptyForm = {
    name:'',
    email: '',
    password: '',
    responsable_id: '',
    responsable: [],
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [clientes, setClientes] = useState([]);

  const editarButton = (row) =>{
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () =>{
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const conditionalRowStyles = [
    {
      when: row => row.activo === 0,
      style: row => ({ backgroundColor: '#ebc5c5' }),
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

  const getData = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('users');

    if(request.status === "success"){
      const newListUsers = request.data.filter(item=> item.id_responsable === null || item.id_responsable === '' );
      setData(newListUsers);
      setClientes(request.clientes);
      setStatus({status:request.status, message:"Se ha creado exitosamente."});
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    
    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(()=>{
    getData();
  },[]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState({id:0,name:"",activo:1});

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete({id:0,name:"",activo:1});
  }

  function openModal(row) {
    setIsOpen(true);
    console.log(row);
    setSelectedDelete({id: row.id, nombre: row.name, activo: row.activo});
  }

  const deleteUser = async () =>{
    closeModal();
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Del(`users/${selectedDelete.id}`);

    if(request.status === "success"){
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const editButton = (row) => <MDButton variant="gradient" 
    color="info" iconOnly onClick={()=>editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const deleteButton = (row) => <MDButton variant="gradient" 
    color="error" iconOnly onClick={()=>openModal(row)}><Icon>not_interested</Icon></MDButton>;

  const activateButton = (row) => <MDButton variant="gradient" 
    color="success" iconOnly onClick={()=>openModal(row)}><Icon>check_box</Icon></MDButton>;

  const columns = [
    {
        name: 'Alias (Nombre)',
        selector: row => row.name,
    },
    {
        name: 'Correo',
        selector: row => row.email,
    },
    {
        name: '',
        selector: row => editButton(row),
        compact: true
    },
    {
        name: '',
        selector: row => parseInt(row.activo,10) === 1 ? deleteButton(row) : activateButton(row),
        compact: true
    },
  ];

  const tableData = {
    columns,
    data,
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
       <MDBox pt={6} pb={3}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                        Usuarios externos
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton variant="gradient" color="light" 
                      onClick={()=>crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                  :
                  <DataTableExtensions
                  {...tableData}
                    export={false}
                    print={false}
                    filterPlaceholder="Buscar..."
                  >
                  <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: 'Clientes por página:', 
                        rangeSeparatorText: 'de', 
                        noRowsPerPage: false, 
                        selectAllRowsItem: true, 
                        selectAllRowsItemText: 'Todos'
                      }}
                      conditionalRowStyles={conditionalRowStyles}
                  />
                  </DataTableExtensions>
                  }
      
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show}  timeout={50}>
            <UserForm data={selectedData} editar={editar} setShow={setShow} getLista={getData} clientesArr = {clientes} />
          </Collapse>
        </Fade>
      </MDBox>
      <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea eliminar al usuario ${selectedDelete.nombre}?`}
          style={customStyles}
        >
          
          <Grid container spacing={2} p={4}>
            <Grid item xs={12} pb={2}>
              <MDTypography variant="h2">¿Seguro que desea {selectedDelete.activo === 1 ? "desactivar" : "reactivar"} el usuario {selectedDelete.nombre}?</MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="info" disabled={loading}
                onClick={()=>closeModal()} fullWidth>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton variant="gradient" color="warning" disabled={loading}
                onClick={()=>deleteUser()} fullWidth>
                Cambiar status
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Usuario;