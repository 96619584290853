import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF , { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import moment from 'moment';
import Logo from './logo.png';
import Logo2 from './logo2.jpg';
import Fondo from './membrete.jpg';

function NewCertGenerator({data, qr}) {

	const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 8,
        lineHeight: 1.2,
        flexDirection: 'column',
        display:"flex",
    }, 
    bgImage: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
    table:{
      width:"92%",
      height:"86%",
      display:"flex",
      borderColor:"#000000",
      borderWidth:1,
      marginLeft:"auto",
      marginRight:"auto",
      marginTop:"auto",
      marginBottom:35,
      fontSize: 9
    },
    row:{
      width: "100%",
      borderColor:"#000000",
      flexDirection: 'row',
    },
    col:{
      borderColor:"#000000",
      flex:1, 
      paddingTop:0,
    },
    borderX:{
      borderRight:1,
      borderLeft:1,
    },
    borderY:{
      borderTop:1,
      borderBottom:1,
    },
    borderS:{
      borderLeft:1,
    },
    borderE:{
      borderRight:1,
    },
    borderT:{
      borderTop:1,
    },
    borderB:{
      borderBottom:1,
    },
    center:{
      verticalAlign:"middle",
      marginTop:"auto",
      marginBottom:"auto",
      paddingLeft:15,
      paddingRight:15,
      paddingTop: 4,
      paddingBottom: 4,
    },
    textCenter:{
      textAlign:"center",
    },
    head:{
      fontWeight:"heavy",
      backgroundColor:"#000000",
      color: "#ffffff",
      fontFamily: 'Helvetica-Bold',
      fontSize: 10,
    },
    mtAuto: {
      marginTop: "auto"
    },
    py2:{
      paddingTop: 2,
      paddingBottom: 2,
    },
    py4:{
      paddingTop: 4,
      paddingBottom: 4,
    },
    px3:{
      paddingLeft: 3,
      paddingRight: 3,
    },
    px10:{
      paddingLeft: 10,
      paddingRight: 10,
    },
    px20:{
      paddingLeft: 20,
      paddingRight: 20,
    },
    pt1:{
      paddingTop: 1,
    },
    pt3:{
      paddingTop: 3,
    },
    pt30:{
      paddingTop: 30,
    },
    pt120:{
      paddingTop: 132,
    },
    p4:{
      padding: 4,
    },
    pb10:{
      paddingBottom: 10,
    },
    flex4:{
      flex:4,
    },
    flex3:{
      flex:3,
    },
    flex2:{
      flex:3,
    },
    flex1:{
      flex:1,
    },
    flex0:{
      display: "inline-flex"
    },
    bold:{
      fontWeight:"heavy",
      fontFamily: 'Helvetica-Bold',
    },
    bolder:{
      fontWeight:"heavy",
      fontFamily: 'Helvetica-Bold',
      fontSize: 12,
    },
    qr:{
      height: 120,
      width: 120,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    font10:{
      fontSize: 11,
    },
    myAuto:{
      marginTop: "auto",
      marginBottom: "auto",
    },
    red:{
      color:"#AB272C"
    },
    firma:{
      height: 30,
      maxWidth: '100%'
    }
  });

  const firstDate = moment(data.fecha_imparticion_curso);
  const fdDay = firstDate.format('DD');
  const fdMonth = firstDate.format('MM');
  const fdYear = firstDate.format('YYYY');
  const lastDate = moment(data.vigencia_fecha);
  const ldDay = lastDate.format('DD');
  const ldMonth = lastDate.format('MM');
  const ldYear = lastDate.format('YYYY');
  const endDate = moment(data.fecha_termino);
  const endDay = endDate.format('DD');
  const endMonth = endDate.format('MM');
  const endYear = endDate.format('YYYY');

  const rellenar = (datos) => datos.map( (c, i) => 
    i === 0 ? 
    <View style={[styles.col, styles.py2, styles.textCenter]}>
      <Text>{c}</Text>
    </View> 
    : 
    <View style={[styles.col, styles.borderS, styles.py2, styles.textCenter]}>
      <Text>{c}</Text>
    </View>
  );

	return (
      <Page size="LETTER" wrap style={styles.page} scale="1.5">
        <Image style={styles.bgImage}  src={Fondo} alt="images" />
        <View style={[styles.row, styles.pt120]}>
          <View style={styles.col}>
            <Text style={[styles.textCenter, styles.bolder]}>FORMATO DC-3</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.col}>
            <Text style={[styles.textCenter, styles.bolder]}>CONSTANCIA DE COMPETENCIAS O DE HABILIDADES LABORALES</Text>
          </View>
        </View>
        <View style={[styles.p4, styles.px20]}>
          <View style={[styles.row, styles.borderX, styles.borderY, styles.head]}>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}>DATOS DEL TRABAJADOR</Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB,]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Nombre (Anotar apellido paterno, apellido materno y nombre (s))</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.nombre_cursante}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB]}>
            <View style={[styles.col, styles.borderE]}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Clave Única de Registro de Población</Text>
              </View>
              <View style={[styles.row, styles.mtAuto]}>
                {rellenar([...data.curp_cursante])}
              </View>
            </View>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Ocupación específica (Catálogo Nacional de Ocupaciones) 1/</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.ocupacion ? data.ocupacion.nombre : ''}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Puesto*</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.puesto}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.p4, styles.px20]}>
          <View style={[styles.row, styles.borderX, styles.borderY, styles.head]}>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}>DATOS DE LA EMPRESA</Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB,]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Nombre o razón social (En caso de persona física, anotar apellido paterno, apellido materno y nombre(s))</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.cursante.cliente.razon_social}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB]}>
            <View style={[styles.col]}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Registro Federal de Contribuyentes con homoclave (SHCP)</Text>
              </View>
              <View style={[styles.row, styles.mtAuto]}>
                {rellenar(data.cursante.cliente.rfc && data.cursante.cliente.rfc !== "" ? [...data.cursante.cliente.rfc] : [..."                 "])}
              </View>
            </View>
            <View style={styles.col} />
          </View>
        </View>
        <View style={[styles.p4, styles.px20]}>
          <View style={[styles.row, styles.borderX, styles.borderY, styles.head]}>
            <View style={styles.col}>
              <Text style={[styles.center,styles.textCenter]}>DATOS DEL PROGRAMA DE CAPACITACIÓN, ADIESTRAMIENTO Y PRODUCTIVIDAD</Text>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB,]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Nombre del curso</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.nombre_curso}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB]}>
            <View style={[styles.col, styles.flex3, styles.borderE]}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Duración en horas</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.duracion_curso}</Text>
              </View>
            </View>
            <View style={[styles.col]}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Periodo de ejecución:</Text>
              </View>
            </View>
            <View style={[styles.flex0, styles.px3, styles.mtAuto]}>
              <View style={styles.row}>
                <Text style={styles.py2}>De</Text>
              </View>
            </View>
            <View style={[styles.col, styles.borderX]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Año</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...fdYear])}
              </View>
            </View>
            <View style={[styles.col, styles.borderE]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Mes</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...fdMonth])}
              </View>
            </View>
            <View style={[styles.col, styles.borderE]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Día</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...fdDay])}
              </View>
            </View>
            <View style={[styles.flex0, styles.px3, styles.mtAuto]}>
              <Text style={[styles.px3, styles.py2]}>a</Text>
            </View>
            <View style={[styles.col, styles.borderX]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Año</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...endYear])}
              </View>
            </View>
            <View style={[styles.col, styles.borderE]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Mes</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...endMonth])}
              </View>
            </View>
            <View style={[styles.col]}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.textCenter}>Día</Text>
                </View>
              </View>
              <View style={styles.row}>
                {rellenar([...endDay])}
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB,]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Área temática del curso 2/</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.curso.area}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.borderX, styles.borderB,]}>
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt1]}>Nombre del agente capacitador o STPS 3/</Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.px3, styles.pt3]}>{data.nombre_instructor}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.p4, styles.px20]}>
          <View style={[styles.borderY]}>
            <View style={[styles.row, styles.borderX, styles.py4]}>
              <View style={styles.col}>
                <Text style={[styles.bold ,styles.textCenter]}>Los datos se asientan en esta constancia bajo protesta de decir verdad, apercibidos de la responsabilidad en que incurre todo</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderX, styles.py4]}>
              <View style={styles.col}>
                <Text style={[styles.bold ,styles.textCenter]}>aquel que no se conduce con verdad.</Text>
              </View>
            </View>
            <View style={[styles.row, styles.borderX, styles.px10, styles.pb10]}>
              <View style={[styles.col]}>
                <View style={[styles.row]}>
                  <View style={styles.col}>
                    <Text style={[styles.textCenter, styles.py4]}>Instructor o tutor</Text>
                  </View>
                </View>
                <View style={[styles.row, styles.px20]}>
                  <View style={[styles.col, styles.px20, styles.borderB]}>
                    <Image style={styles.firma}  src={data.firma_instructor} alt="images" />
                  </View>
                </View>
                <View style={[styles.row]}>
                  <View style={[styles.col]}>
                    <Text style={styles.textCenter}>{data.nombre_instructor}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.col]}>
                <View style={[styles.row]}>
                  <View style={styles.col}>
                    <Text style={[styles.textCenter, styles.py4]}>Patrón o representante legal 4/</Text>
                  </View>
                </View>
                <View style={[styles.row, styles.px20]}>
                  <View style={[styles.col, styles.pt30, styles.px20, styles.borderB]} />
                </View>
                <View style={[styles.row]}>
                  <View style={[styles.col]}>
                    <Text style={styles.textCenter}>{data.nombre_patron}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.col]}>
                <View style={[styles.row]}>
                  <View style={styles.col}>
                    <Text style={[styles.textCenter, styles.py4]}>Representante de los trabajadores 5/</Text>
                  </View>
                </View>
                <View style={[styles.row, styles.px20]}>
                  <View style={[styles.col, styles.pt30, styles.px20, styles.borderB]} />
                </View>
                <View style={[styles.row]}>
                  <View style={[styles.col]}>
                    <Text style={styles.textCenter}>{data.nombre_representante}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.row, styles.py10, styles.px20]}>
          <View style={[styles.col, styles.myAuto, styles.flex2]}>
            <View>
              <Text style={[styles.textCenter, styles.font10]}>No. de Certificado</Text>
            </View>
            <View>
              <Text style={[styles.textCenter, styles.red, styles.font10]}>{data.folio_unico_registro}</Text>
            </View>
          </View>
          <View style={[styles.col, styles.flex1]} />
          <View style={[styles.col, styles.myAuto, styles.flex2]}>
            <View style={[styles.row]}>
              <View style={[styles.col, styles.myAuto]}>
                <Text style={[styles.textCenter, styles.font10]}>Escanea el código QR para autenticar</Text>
              </View>
              <View style={[styles.col]}>
                <Image style={styles.qr} src={qr} alt="images" />
              </View>
            </View>
          </View>
        </View>
      </Page>
	);
}

NewCertGenerator.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
  qr: PropTypes.node.isRequired
};

export default NewCertGenerator;