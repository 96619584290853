import React, {useMemo} from 'react';
import { Outlet , Navigate } from 'react-router-dom';
import PropTypes from "prop-types";
 
function RouteGuard(){
   // check user has JWT token
   if(localStorage.getItem("user")){
      return <Outlet />
   }
   return <Navigate to="/login" />
       

};

export default RouteGuard;