/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ReactTooltip from 'react-tooltip';

import { Link, useParams } from "react-router-dom";

function DefaultInfoCard({ data }) {

	function donwloadFile(filename, file) {
	  const downloadLink = document.createElement("a");

	  downloadLink.href = file;
	  downloadLink.download = filename;
	  downloadLink.click();
	}

	function getIconType(file){
		const firstChars = file.substring(0, 35);
		if(firstChars.includes("data:image")){
			return "image";
		}
		if(firstChars.includes("data:application/pdf")){
			return "picture_as_pdf";
		}
		if(firstChars.includes("data:application")){
			return "article";
		}
		return "article";
	}

	function listaEvidencias(respuesta) { return respuesta.evidencias.length > 0 ? respuesta.evidencias.map((evidencia)=>
			<Grid item my="auto" display="flex">
  			<div data-tip={`${evidencia.nombre}<br>${evidencia.descripcion ? evidencia.descripcion : ""}`}>
	  			<MDBox
	          display="grid"
	          justifyContent="center"
	          alignItems="center"
	          bgColor="info"
	          color="white"
	          width="3rem"
	          height="3rem"
	          shadow="md"
	          borderRadius="lg"
	          variant="gradient"
	          onClick={()=>donwloadFile(evidencia.nombre, evidencia.evidencia)}
	        >
	          <Icon fontSize="default">{getIconType(evidencia.evidencia)}</Icon>
	        </MDBox>
        </div>
  		</Grid>
		) : <Grid item my="auto" display="flex">
					<MDTypography variant="caption" color="text">
						No se han subido evidencias todavía.
					</MDTypography>
				</Grid>
		};

	const listaPreguntas = data.servicio.preguntas.map((pregunta)=>
			<Fade in timeout={800}>
				<Grid item xs={6} mx="auto" py={1} px={2}>
					<Card>
						<MDBox p={3}>
							<Grid container textAlign="right">
								<Grid item xs={6}>
					      	<MDTypography display="flex" variant="h5" color="text" fontWeight="bold">
										{pregunta.pregunta}
									</MDTypography>
								</Grid>
								<Grid item xs={6} my="auto">
									<MDTypography display="inline" variant="h6" color="text" fontWeight="regular">
										<Icon>schedule</Icon> {pregunta.tiempo_aproximado} dia(s)
									</MDTypography>
								</Grid>
							</Grid>
							
							{
								pregunta.respuesta ? 
								<MDBox>
									<MDTypography display="block" variant="h6" color="text" fontWeight="regular" pt={1}>
										Respuesta: {pregunta.respuesta.respuesta}
									</MDTypography>
									<MDTypography display="flex" variant="h6" color="text" fontWeight="bold" pt={1}>
										Evidencias
									</MDTypography>
									<Grid container spacing={1}>
										{listaEvidencias(pregunta.respuesta)}
									</Grid>
								</MDBox> : 
								<MDTypography display="flex" variant="caption" color="text">
									No se ha registrado respuesta / evidencias.
								</MDTypography>
							}
						</MDBox>
					</Card>
	      </Grid>
      </Fade>
	);

  return (
    <MDBox>
    	<ReactTooltip multiline place="bottom" className="z-101" />
      <MDBox pb={2}>
      <hr />
      </MDBox>
      <MDBox pb={2} lineHeight={1.25}>
      	<MDTypography variant="h3" fontWeight="medium" textTransform="capitalize" textAlign="center" pb={2}>
					Acciones
				</MDTypography>
	    <Grid container spacing={1}>
  			{listaPreguntas}
  			<Grid item xs={6}/>
      	</Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  // value: "",
  data: []
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

// color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),

export default DefaultInfoCard;
